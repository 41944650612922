import {
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import cx from 'classnames'
import { isA } from 'expect/build/jasmineUtils'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import QueryString from 'qs'
import React from 'react'

import { getState } from '../api/api'
import {
  userContext,
  withUserContext,
  withUserContextProps
} from '../context/userContext'

import { BlackFriday } from './2024_forAll/BlackFriday'
import Answers from './Answers'
import Averages from './Averages'
import Image1 from './Banners/1.png'
import Image5 from './Banners/1_1.png'
import Image6 from './Banners/1_2.png'
import Image7 from './Banners/1_3.png'
import Image8 from './Banners/1_4.png'
import Image9 from './Banners/1_5.png'
import Image10 from './Banners/1_6.png'
import Image2 from './Banners/2.png'
import Image3 from './Banners/3.png'
import Image4 from './Banners/4.png'
import { BlackFridayClub } from './BlackFriday/BlackFridayClub'
import { BlackFridayProfinansy } from './BlackFriday/BlackFridayProfinansy'
import { Form } from './BlackFriday/components/Form/Form'
import { Timer } from './BlackFriday/components/Timer/Timer'
import Bron from './Bron'
import Comments from './Comments'
import comments from './Comments'
import Footer from './Footer'
import New_payment from './New_payment'
import PayButtons from './PayButtons'
import PlansWidget from './PlansWidget'
import Polls from './Polls'
import Present from './Present'
import Present2 from './Present2'
import { Tabs } from './Tabs'
import TranslationBlock from './TranslationBlock'
import Victorina from './Victorina'
import WheelPolls from './WheelPolls'
import { withMainContext, withMainContextProps } from './lib'
import whileRequest from './lib/whileRequest'

// const a = [
//   {
//     type: 'pay_buttons_new',
//     data: [],
//     tabs: [
//       {
//         title: 'Подписка profinasy.ru',
//         isReverse: true,
//         items: [
//           {
//             type: 'new_curs_1',
//             typeCard: 'new_curs',
//             isFull: false
//           },
//           {
//             type: 'new_curs_2',
//             typeCard: 'new_curs',
//             isFull: false
//           }
//         ]
//       }
//     ]
//   }
// ]

//console.log( JSON.stringify(a, null, 4) );

interface Props extends WithStyles<typeof style>, withUserContextProps {}

const style = (theme: Theme) =>
  createStyles({
    box: {
      background: '#030303',
      color: '#fff'
    },
    celebrateBackground: {
      // position: 'absolute',
      // top: '0',
      // left: '0',
      width: '100%',
      height: '60vh',
      // zIndex: 1,
      minHeight: '200px',
      backgroundImage: 'url("assets/2024_forAll/background2024.png")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',

      [theme.breakpoints.down(1100)]: {
        height: '50vh'
      },
      [theme.breakpoints.down(900)]: {
        height: '40vh'
      },
      [theme.breakpoints.down(730)]: {
        height: '30vh'
      },
      [theme.breakpoints.down(550)]: {
        height: '20vh'
      }
    },

    endBfBackground: {
      width: '100%',
      height: '100vh',
      minHeight: '200px',
      backgroundImage: 'url("assets/2024_forAll/bfEnd.png")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [theme.breakpoints.down(1170)]: {
        height: '80vh'
      },
      [theme.breakpoints.down(950)]: {
        height: '70vh'
      },
      [theme.breakpoints.down(810)]: {
        height: '60vh'
      },
      [theme.breakpoints.down(700)]: {
        height: '50vh'
      },
      [theme.breakpoints.down(590)]: {
        height: '40vh'
      },
      [theme.breakpoints.down(470)]: {
        height: '30vh'
      }
    },

    header: {
      position: 'relative',
      zIndex: 2,
      color: '#FE21E0',
      textAlign: 'center',
      fontSize: '90px',
      fontWeight: 900,
      textTransform: 'uppercase',
      lineHeight: '104%',
      marginBottom: '24px',
      [theme.breakpoints.down(900)]: {
        fontSize: 60
      },
      [theme.breakpoints.down(600)]: {
        fontSize: 30
      }
    },
    blocks: {
      zIndex: 2,
      margin: '0 auto',
      position: 'relative'
    },
    title: {
      fontFamily: 'Manrope',
      fontWeight: 800,
      fontSize: 42,
      lineHeight: '125%',
      textAlign: 'center',
      textTransform: 'uppercase',
      zIndex: 2,
      position: 'relative',
      marginBottom: 0,

      '& > span': {
        background: 'linear-gradient(90deg, #FA9233 56.63%, #DD3320 103.72%)',
        backgroundClip: 'text',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: 24,
        marginBottom: 0
      }
    },
    subtitle: {
      fontFamily: 'Manrope',
      color: '#ffffff',
      fontSize: '24px',
      lineHeight: '120%',
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginTop: 16,
      marginBottom: 40,
      display: 'block'
    },
    widget: {
      padding: '30px 0'
    },
    widgetAdmin: {
      padding: 0
    },
    blocksAdmin: {
      padding: '30px 70px !important'
    },
    bigText: {
      fontSize: 30,
      textAlign: 'center',
      padding: '10px 0',
      fontWeight: 700
    },
    banner: {
      paddingTop: 10,
      paddingBottom: 10,
      '&>img': {
        maxWidth: '100%'
      }
    },
    videoResponsive: {
      position: 'relative',
      zIndex: 2
    },
    timerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: '29px 0px 66px',

      [theme.breakpoints.down(900)]: {
        padding: '29px 90px'
      },
      [theme.breakpoints.down(620)]: {
        padding: '20px 30px'
      },
      [theme.breakpoints.down(440)]: {
        padding: '10px'
      }
    },
    videoContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: 16,
      maxWidth: 1400,
      margin: '0 auto 20px auto',
      [theme.breakpoints.down(600)]: {
        flexDirection: 'column'
      }
    },
    chat: {
      width: '400px',
      position: 'relative',
      zIndex: 100,
      maxWidth: '400px',
      height: '500px',
      [theme.breakpoints.down(1000)]: {
        width: '300px'
      },
      [theme.breakpoints.down(600)]: {
        width: '100%',
        maxWidth: '100%'
      }
    },
    iframe: {
      aspectRatio: '16 / 9',
      border: 'none',
      width: '900px',
      height: '100%',
      [theme.breakpoints.down(1250)]: {
        width: '600px'
      },
      [theme.breakpoints.down(1000)]: {
        width: '400px'
      },
      [theme.breakpoints.down(800)]: {
        width: '300px'
      },
      [theme.breakpoints.down(600)]: {
        width: '100%',
        maxWidth: '100%'
      }
    }
  })

const Banners = {
  1: Image1,
  2: Image2,
  3: Image3,
  4: Image4,

  '1_1': Image5,
  '1_2': Image6,
  '1_3': Image7,
  '1_4': Image8,
  '1_5': Image9,
  '1_6': Image10
}

class Main extends React.PureComponent<Props> {
  state = {
    loading: 0,
    state: null,
    onlineCount: 0,
    ukey: Math.random().toString(),
    clearSoldOut: false
  }

  request: any
  timeout: any
  loading = false

  _load = () => {
    if (this.loading) return

    this.setState({
      loading: 1
    })

    this.loading = true

    this.request = whileRequest(getState, {}, this.props.ukey)

    this.request.then((data) => {
      this.loading = false

      this.setState({
        loading: 3,
        state: data.data,
        onlineCount: data.onlines
      })

      this.timeout = setTimeout(() => {
        this._load()
      }, 30000)
    })
  }

  componentDidMount() {
    //console.log(this.props)
    const parse = QueryString.parse(window.location.search || '', {
      ignoreQueryPrefix: true
    }) as any

    if (parse.access === 'bfadmin121') {
      this.setState({ isAdmin: true })
    }

    if (parse.clear === 'soldout') {
      this.setState({ clearSoldOut: true })
    }

    if (parse.offline === 'true') {
      let blocks = []

      //console.log(parse);

      if (parse.type === 'buttons' && parse.plans) {
        blocks = [
          {
            type: 'pay_buttons',
            data: [],
            payInfo: parse.plans.split(',').map((el) => {
              return {
                type: 'button_pay',
                tariff: el,
                isFull: false
              }
            })
          }
        ]
      } else if (parse.type === 'islam' && parse.plans) {
        blocks = [
          {
            type: 'pay_buttons',
            data: [],
            payInfo: parse.plans.split(',').map((el) => {
              return {
                type: 'tarif_new',
                tariff: el,
                isFull: false
              }
            })
          }
        ]
      } else if (parse.type === 'wfull' && parse.plans) {
        blocks = [
          {
            type: 'pay_buttons',
            data: [],
            payInfo: parse.plans.split(',').map((el) => {
              return {
                type: 'tarif',
                tariff: el,
                isFull: false
              }
            })
          }
        ]
      } else if (parse.type === 'ifull' && parse.plans) {
        blocks = [
          {
            type: 'pay_buttons',
            data: [],
            payInfo: parse.plans.split(',').map((el) => {
              return {
                type: 'tarif',
                tariff: el,
                isFull: true
              }
            })
          }
        ]
      } else if (parse.type === 'ibr' && parse.plans) {
        blocks = parse.plans.split(',').map((el) => {
          return {
            type: 'pay_buttons',
            data: [],
            payInfo: [
              {
                type: 'bron',
                tariff: el,
                isFull: true
              }
            ]
          }
        })
      } else if (parse.type === 'days' && parse.plans) {
        if (parse.plans === 'day_first') {
          blocks = [
            {
              type: 'pay_buttons_new',
              data: [],
              tabs: [
                {
                  title: 'test',
                  isReverse: true,
                  items: [
                    {
                      type: 'metod_1',
                      typeCard: 'metod',
                      isFull: false,
                      buttons: ['full', 'bron', 'etap']
                    },
                    {
                      type: 'metod_2',
                      typeCard: 'metod',
                      isFull: false,
                      buttons: ['full', 'bron', 'etap']
                    }
                  ]
                }
              ]
            }
          ]
        } else if (parse.plans === 'day_second') {
          blocks = [
            {
              type: 'pay_buttons_new',
              data: [],
              tabs: [
                {
                  title: 'Подписка profinasy.ru',
                  isReverse: true,
                  items: [
                    {
                      type: 'platforma_1',
                      typeCard: 'platforma',
                      isFull: false
                    },
                    {
                      type: 'platforma_2',
                      typeCard: 'platforma',
                      isFull: false
                    },
                    {
                      type: 'platforma_3',
                      typeCard: 'platforma',
                      isFull: false
                    }
                  ]
                },
                {
                  title: 'Клуб pro.finansy',
                  isReverse: true,
                  items: [
                    {
                      type: 'club_1',
                      typeCard: 'club',
                      isFull: false
                    },
                    {
                      type: 'club_2',
                      typeCard: 'club',
                      isFull: false
                    }
                  ]
                }
              ]
            }
          ]
        } else if (parse.plans === 'day_third') {
          blocks = [
            {
              type: 'pay_buttons_new',
              data: [],
              tabs: [
                {
                  title: 'Подписка profinasy.ru',
                  isReverse: true,
                  items: [
                    {
                      type: 'new_curs_1',
                      typeCard: 'new_curs',
                      isFull: false
                    }
                  ]
                }
              ]
            }
          ]
        }
      } else if (parse.type === 'andrey') {
        blocks = [
          {
            type: 'new_payment',
            data: [
              'Купить навсегда',
              'https://payment.profinansy.ru/796',
              'https://payment.profinansy.ru/797'
            ]
          },
          {
            type: 'new_payment',
            data: [
              'Купить на 6 месяцев за 4380Р',
              'https://payment.profinansy.ru/796',
              'https://payment.profinansy.ru/797'
            ]
          },
          {
            type: 'new_payment',
            data: [
              'Купить на 12 месяцев за 9860Р',
              'https://payment.profinansy.ru/796',
              'https://payment.profinansy.ru/797'
            ]
          },
          {
            type: 'present2',
            data: ['https://yandex.ru', 'записаться']
          },
          {
            type: 'pay_buttons',
            data: [],
            tabs: [
              {
                title: 'Клуб',
                isReverse: true,
                items: [
                  {
                    type: 'club_1',
                    typeCard: 'club',
                    isFull: false
                  },
                  {
                    type: 'club_2',
                    typeCard: 'club',
                    isFull: false
                  }
                ]
              },
              {
                title: 'Платформа',
                isReverse: true,
                items: [
                  {
                    type: 'platforma_1',
                    typeCard: 'platforma',
                    isFull: false
                  },
                  {
                    type: 'platforma_2',
                    typeCard: 'platforma',
                    isFull: false
                  },
                  {
                    type: 'platforma_3',
                    typeCard: 'platforma',
                    isFull: false
                  }
                ]
              }
            ]
          }
        ]
      } else if (parse.type === 'bron_new') {
        blocks = [
          {
            type: 'bron_new',
            data: ['test']
          },
          {
            type: 'pay_buttons_new',
            data: [],
            tabs: [
              {
                title: 'test',
                isReverse: true,
                items: [
                  {
                    type: 'metod_1',
                    typeCard: 'metod',
                    isFull: false,
                    buttons: ['full', 'bron', 'etap']
                  },
                  {
                    type: 'metod_2',
                    typeCard: 'metod',
                    isFull: false,
                    buttons: ['full', 'bron', 'etap']
                  }
                ]
              }
            ]
          }
        ]
      }

      this.setState({
        loading: 3,
        state: {
          blocks
          //translation_link: 'test',
          //commentsHideMobile: true,
          //translation_type: 'youtube',
          //comments: true,
          // customButton: 'перейти к оплатам'
        }
      })
    } else {
      this._load()
    }
    //this._load();
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.abort()
    }

    clearTimeout(this.timeout)
  }

  render() {
    const { classes } = this.props

    const isClub = !!window.location.pathname.match(/\/chk/)
    const isBlackFriday = !!window.location.pathname.match(/\/black-friday/)
    const isDay1 = !!window.location.pathname.match(/\/day1/)
    const isDay2 = !!window.location.pathname.match(/\/day2/)
    const isDay3 = !!window.location.pathname.match(/\/day3/)
    const isOP = !!window.location.pathname.match(/\/op/)

    const videoLink = this.state.state?.translation_link?.split(':') || []

    if (this.state.state === null) return null

    if (this.state.state.redirect) {
      window.location.href = this.state.state.redirect

      return null
    }

    //@ts-ignore

    // const isAdmin = this.props.user_id === 28159

    const isAdmin = this.state?.isAdmin

    //@ts-ignore
    const canComment = this.props.user_id === 904430

    //@ts-ignore
    const fastComments = this.props.user_id === 915529

    // const blocks = [
    //   {
    //     type: 'friday',
    //     data: [],
    //     tabs: [],
    //     imm: [],
    //     payInfo: [],
    //     victorinaInfo: []
    //   }
    // ]

    const isEndBFForAll = !this.state?.clearSoldOut

    const needInsert = this.state.state.blocks.map((el, index) => {
      // const needInsert = blocks.map((el, index) => {
      return (
        <div id={index === 0 ? 'first_block___' : undefined}>
          {(() => {
            if (el.type === 'baner_link') {
              const data = el.data

              return (
                <Grid
                  key={index}
                  container
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  spacing={4}
                >
                  {el.data.map((el, index) => {
                    if (index % 2 !== 0) return null

                    const img = Banners[el]
                    if (!img) return null

                    return (
                      <Grid item xs={12} md={6}>
                        <a
                          href={data[index + 1]}
                          target={'_blank'}
                          style={{
                            textDecoration: 'none'
                          }}
                          rel="noreferrer"
                        >
                          <div className={classes.banner}>
                            <img src={img} />
                          </div>
                        </a>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            }

            if (el.type === 'baner') {
              return (
                <Grid
                  container
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  spacing={4}
                >
                  {el.data.map((el) => {
                    const img = Banners[el]
                    if (!img) return null

                    return (
                      <Grid item xs={12} md={6}>
                        <div className={classes.banner}>
                          <img src={img} />
                        </div>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            }

            if (el.type === 'text' && !isAdmin) {
              return (
                <div
                  id={index === 0 ? 'first_block__' : undefined}
                  className={classes.bigText}
                >
                  {el.data[0]}
                </div>
              )
            }

            if (el.type === 'pay_buttons' && !isAdmin) {
              return (
                <div
                  key={index}
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                >
                  <PayButtons data={el.payInfo || []} imm={!!el.imm} />
                </div>
              )
            }

            if (el.type === 'friday') {
              return (
                <BlackFriday
                  deleteArr={el.data}
                  version={this.state.state?.version}
                />
              )
              // if (isBlackFriday || isDay1 || isDay2 || isDay3 || isOP) {
              //   return (
              //     <BlackFridayProfinansy
              //       // isTranslation={
              //       //   !!(
              //       //     this.state.state.translation_type &&
              //       //     this.state.state.translation_link
              //       //   )
              //       // }
              //       isTranslation={true}
              //       isDay1={isDay1}
              //       isDay2={isDay2}
              //       isDay3={isDay3}
              //       isOP={isOP}
              //     />
              //   )
              // } else {
              //   return (
              //     <>
              //       {/*<Form />*/}
              //       <BlackFridayClub data={el.data} isClub={isClub} />
              //     </>
              //   )
              // }
            }

            if (el.type === 'bron_new' && !isAdmin) {
              return <Bron title={el.data[0]} />
            }

            if (el.type === 'new_payment' && !isAdmin) {
              return <New_payment data={el.data} />
            }

            if (el.type === 'pay_buttons_new' && !isAdmin && el.tabs?.length) {
              return <Tabs tabs={el.tabs as any} />
            }

            if (el.type === 'answers' && !isAdmin) {
              return (
                <div
                  key={index + el.data[0]}
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                >
                  <Answers data={el.data} />
                </div>
              )
            }

            if (el.type === 'answers' && !isAdmin) {
              return (
                <div
                  key={index + el.data[0]}
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                >
                  <Answers data={el.data} />
                </div>
              )
            }

            if (el.type === 'polls') {
              return (
                <div
                  key={el.data.join('')}
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                >
                  <Polls data={el.data} isAdmin={isAdmin} />
                </div>
              )
            }

            if (el.type === 'wheel_polls') {
              return (
                <div
                  key={el.data.join('')}
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                >
                  <WheelPolls data={el.data} isAdmin={isAdmin} />
                </div>
              )
            }

            if (el.type === 'averages') {
              return (
                <div
                  key={el.data.join('')}
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                >
                  <Averages
                    data={el.data}
                    key={el.data.join('')}
                    isAdmin={isAdmin}
                  />
                </div>
              )
            }

            if (el.type === 'present' && !isAdmin) {
              return (
                <div
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                  key={index}
                >
                  <Present
                    link={el.data[0]}
                    title={el.data[1]}
                    subLink={el.data[2]}
                    subTitle={el.data[3]}
                    key={index}
                  />
                </div>
              )
            }

            if (el.type === 'present2' && !isAdmin) {
              return (
                <div
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                  key={index}
                >
                  <Present2 link={el.data[0]} title={el.data[1]} key={index} />
                </div>
              )
            }

            if (el.type === 'victorina' && !isAdmin) {
              return (
                <div
                  className={cx(classes.widget, {
                    [classes.widgetAdmin]: isAdmin
                  })}
                  key={index}
                >
                  <Victorina info={el.victorinaInfo} />
                </div>
              )
            }

            return null
          })()}
        </div>
      )
    })

    return isEndBFForAll ? (
      <div className={classes.box}>
        <div className={classes.endBfBackground} />
        <Footer />
      </div>
    ) : (
      <>
        <div className={classes.celebrateBackground} />
        <div className={classes.box}>
          {this.state.state.blocks?.map((el, i) =>
            el.data.some(
              (item) =>
                item === 'friday_translation' ||
                item === 'friday_translation_button'
            ) ? (
              <div className={classes.videoContainer} key={i}>
                <div className={classes.videoResponsive}>
                  <iframe
                    className={classes.iframe}
                    src={`https://vimeo.com/event/${videoLink[0]}/embed/${videoLink[1]}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
                {this.state.state?.comments && (
                  <div className={classes.chat}>
                    <Comments
                      isAdmin={isAdmin}
                      online={this.state.onlineCount}
                      ukey={this.state.ukey}
                      fastComments={false}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className={classes.timerContainer} key={i}>
                <Timer onlineCount={this.state.onlineCount} />
              </div>
            )
          )}

          {/* <TranslationBlock*/}
          {/*  translation_type={'youtube'}*/}
          {/*  translation_link={videoRecordLink}*/}
          {/*  online={this.state.onlineCount}*/}
          {/*  isAdmin={canComment}*/}
          {/*  canComments={this.state.state.comments}*/}
          {/*  fastComments={fastComments}*/}
          {/*  customButton={this.state.state.customButton}*/}
          {/*  commentsHideMobile={this.state.state.commentsHideMobile}*/}
          {/*/>*/}
          <div
            className={cx(classes.blocks, {
              [classes.blocksAdmin]: isAdmin
            })}
          >
            {needInsert}
          </div>

          {!isAdmin ? <Footer /> : null}
        </div>
      </>
    )
  }
}

export default withUserContext(withStyles(style)(Main))
