import { Theme, createStyles, makeStyles } from '@material-ui/core'

interface IProps {
  isBig: boolean
  minHeight?: number
}

export const useCardStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: (props) => (props.isBig ? '100%' : '300px'),
      background: '#FFF',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(750)]: {
        maxWidth: '100% !important'
      }
    },

    title: {
      padding: '10px 38px',
      display: 'flex',
      alignItems: 'center',
      gap: 56,
      justifyContent: 'space-between',
      [theme.breakpoints.down(500)]: {
        flexWrap: 'wrap',
        gap: 20
      }
    },
    text: {
      '& span': {
        color: '#000',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '120%',
        [theme.breakpoints.down(600)]: {
          fontSize: '14px'
        }
      }
    },

    count: {
      padding: '4px 10px',
      height: '20px',
      flexShrink: 0,
      borderRadius: '4.381px',
      background: '#EC1656',
      color: '#FFF',
      fontSize: '9px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
      [theme.breakpoints.down(600)]: {
        fontSize: '7px'
      }
    },

    content: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 10,
      background: '#fff',
      padding: '15px 21px 20px 29px',
      borderRadius: '0 0 20px 20px',
      height: '100%',

      [theme.breakpoints.down(900)]: {
        flexDirection: 'column'
      },
      [theme.breakpoints.down(600)]: {
        padding: '15px 12px 20px 20px'
      }
    },

    side: {
      flex: '1',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 20,
      width: '100%',
      minHeight: (props) => (props.minHeight ? props.minHeight : null),

      [theme.breakpoints.down(900)]: {
        minHeight: (props) => (props.minHeight ? 0 : null)
      }
    },

    image: {
      position: 'relative',
      top: '-40px',
      width: '100%',
      marginBottom: '-40px',
      aspectRatio: (props) => (props.isBig ? '1 / 0.3' : '1 / 0.8'),
      '& > img': {
        position: 'absolute',
        left: '53%',
        transform: 'translate(-50%, 0%)',
        display: 'block',
        margin: '0px auto',
        width: 'auto',
        maxHeight: '100%'
      }
    },

    mainProducts: {
      padding: '10px 12px',
      borderRadius: '6px',
      background: '#DCFF90',
      marginBottom: 20
    },

    mainItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
      marginBottom: 5
    },

    leftText: {
      display: 'flex',
      alignItems: 'center',
      gap: 6
    },

    newIcon: {
      margin: '0 20px 0 -25px',
      padding: '4px',
      borderRadius: '3.75px',
      background: '#3A0EFC',
      color: '#FFF',
      fontSize: '8.289px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
      textTransform: 'uppercase'
    },

    list: {
      listStyleType: 'disc',
      padding: '0 12px 0 8px'
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
      marginBottom: 5
    },
    name: {
      color: '#000',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '120%',
      [theme.breakpoints.down(600)]: {
        fontSize: '14px'
      }
    },

    name2: {
      color: '#000',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '120%',
      [theme.breakpoints.down(600)]: {
        fontSize: '14px'
      }
    },
    price: {
      color: '#101010',
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '120%',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 12,
        left: 2,
        width: '100%',
        height: '100%',
        borderTop: '2px solid red',
        transform: 'rotate(-10deg)',
        transformOrigin: 'left'
      }
    },

    rightText: {
      borderRadius: '1000px',
      background: '#74AA00',
      color: '#FFF',
      fontSize: '8px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120%',
      padding: '2px 8px'
    },

    subTitleContainer: {
      padding: '0 12px 0 8px',
      display: 'flex',
      alignItems: 'center',
      gap: 12
    },
    new: {
      position: 'relative',
      '& > img': {
        width: '80px'
      },
      '& > span': {
        position: 'absolute',
        left: '14%',
        top: '18%',
        color: '#101010',
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '120%'
      }
    },
    new2: {
      position: 'relative',
      '& > img': {
        width: '120px'
      }
    },
    description: {
      color: '#101010',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '120%',
      marginTop: 0,
      marginBottom: 10,
      maxWidth: 240,
      [theme.breakpoints.down(600)]: {
        fontSize: '11px'
      }
    },
    contentContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down(600)]: {
        flexDirection: 'column',
        gap: 20
      }
    },

    descBox: {
      [theme.breakpoints.down(600)]: {
        alignSelf: 'flex-start'
      }
    },

    more: {
      color: '#EC1656',
      textDecoration: 'underline',
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '10px',
      background: 'none',
      border: 'none',
      padding: '0px',
      cursor: 'pointer'
    },
    prices: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 5
    },
    oldPrice: {
      position: 'relative',
      color: '#191919',
      fontWeight: 700,
      fontSize: 15,
      lineHeight: '135%',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 21,
        left: 4,
        width: '100%',
        height: '100%',
        borderTop: '2px solid red',
        transform: 'rotate(-12deg)',
        transformOrigin: 'left'
      }
    },
    newPrice: {
      position: 'relative',
      color: '#191919',
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '135%'
    },

    payCredit: {
      position: 'relative',
      '& > span': {
        position: 'absolute',
        left: 22,
        top: 8,
        color: '#242424',
        fontWeight: 700,
        fontSize: 15,
        lineHeight: '125%',
        whiteSpace: 'nowrap'
      }
    },
    buttons: {
      display: 'flex',
      gap: 8,
      width: '100%',
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column'
      }
    },
    buttonDiscount: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      boxShadow: '0px 2.557px 0px 0px #A43191',
      background: '#EC1656',
      color: '#fff',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '17px',
      height: '42px'
    },
    buttonCart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
      width: '100%',
      borderRadius: 8,
      cursor: 'pointer',
      border: '2px solid #EC1656',
      background: 'transparent',
      color: '#EC1656',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '17px',
      height: '45px'
    },
    buttonTinkoff: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      background: '#FFDD2D',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12
    },
    tinkoffText: {
      fontSize: 12,
      color: '#333333',
      fontWeight: 700,
      display: 'block',
      textAlign: 'left'
    },
    tinkoffSubText: {
      fontSize: 10,
      color: '#333333',
      fontWeight: 400,
      display: 'block',
      textAlign: 'left'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column'
      }
    },
    modalImage: {
      maxWidth: '300px',
      minWidth: '300px',
      [theme.breakpoints.down(600)]: {
        minWidth: 'auto'
      }
    },
    descriptionModal: {
      minWidth: '400px',
      marginBottom: 20,
      '& h2': {
        fontSize: 24,
        marginTop: 0
      },
      '& h3': {
        fontSize: 18,
        marginTop: 20,
        marginBottom: 0
      },
      '& ul': {
        padding: '0px 14px',
        margin: '10px 0 10px'
      },
      '& span': {
        display: 'block',
        color: '#898989'
      },
      [theme.breakpoints.down(900)]: {
        minWidth: 'auto'
      }
    },

    pricesForModal: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      marginBottom: 20
    },
    oldPriceForModal: {
      position: 'relative',
      color: '#191919',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '135%',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 21,
        left: 4,
        width: '100%',
        height: '100%',
        borderTop: '2px solid red',
        transform: 'rotate(-12deg)',
        transformOrigin: 'left'
      }
    },
    newPriceForModal: {
      position: 'relative',
      '& > span': {
        position: 'absolute',
        left: 22,
        top: 8,
        color: '#242424',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '125%'
      }
    },
    buttonsForModal: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      width: '100%'
    },

    soldout: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& > img': {
        maxWidth: '250px',
        [theme.breakpoints.down(600)]: {
          maxWidth: '200px'
        }
      }
    }
  })
)
