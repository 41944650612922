import React from 'react'

import { CARDS2 } from '../../cardsSmall.const'
import { CardSmall2 } from '../CardSmall2/CardSmall2'
import { CardSmall } from '../CardSmall/CardSmall'

import { useBannerStyles } from './Friday5Block.styled'

interface IProps {
  deleteArr: string[]
  clearSoldOut?: boolean
}

const Friday5Block = ({ deleteArr, clearSoldOut }: IProps) => {
  const classes = useBannerStyles()

  const filterCards = (items) => {
    return items.filter((cartItem) => !deleteArr.includes(cartItem.key))
  }

  return (
    <div className={classes.pinkBlock}>
      <p className={classes.text}>
        Для <span>коллекционеров</span>
      </p>

      <div className={classes.cardsSmall}>
        {filterCards(CARDS2).map((card, index) => (
          <CardSmall2 data={card} key={index} clearSoldOut={clearSoldOut} />
        ))}
      </div>
    </div>
  )
}

export { Friday5Block }
