import { Dialog, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'

import { getLocalStringNumber } from '../../../../utils/getLocalStringNamber'
import { addToCart } from '../../utils'

import { useCardStyles } from './CardSmall.styled'

interface IProps {
  data: {
    id: string
    key: string
    image: string
    name: string
    description: string
    price: number
    oldPrice: number
    descriptionModal: string
    buttons: { type: string; links?: string[] }[]
    soldout?: boolean
  }
  clearSoldOut?: boolean
}

const CardSmall = ({ data, clearSoldOut }: IProps) => {
  const classes = useCardStyles()

  const [openDescription, setOpenDescription] = useState(false)

  const handleOpenCart = () => {
    addToCart({
      name: data.name,
      price: data.price,
      key: data.key,
      id: data.id
    })
  }

  const getButtons = (item) => {
    if (item.type === 'discount') {
      return (
        <button className={classes.buttonDiscount} onClick={handleOpenCart}>
          Оплатить со скидкой
        </button>
      )
    }
    if (item.type === 'cart') {
      return (
        <button className={classes.buttonCart} onClick={handleOpenCart}>
          <img src="/assets/2024_forAll/cartIcon.png" />
          Добавить в корзину
        </button>
      )
    }
    return null
  }

  return !data.soldout || clearSoldOut ? (
    <>
      <div className={classes.container}>
        <div className={classes.image}>
          <img src={data.image} />
        </div>

        <div className={classes.content}>
          <h3 className={classes.title}>{data.name}</h3>
          <p className={classes.description}>{data.description}</p>
          <div className={classes.contentContainer}>
            <button
              className={classes.more}
              onClick={() => setOpenDescription(true)}
            >
              Подробнее о курсе
            </button>
            <div className={classes.prices}>
              <span className={classes.oldPrice}>
                {getLocalStringNumber(data.oldPrice)}&nbsp;р.
              </span>
              <span className={classes.newPrice}>
                <img src="/assets/2024/price.svg" />
                <span>{getLocalStringNumber(data.price)}&nbsp;р.</span>
              </span>
            </div>
            {data.soldout && !clearSoldOut ? (
              <div className={classes.soldout}>
                <img src="/assets/2024_forAll/soldout.png" />
              </div>
            ) : (
              <div className={classes.buttons}>
                {data.buttons.map((button, index) => (
                  <React.Fragment key={index}>
                    {getButtons(button)}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        maxWidth="md"
        open={!!openDescription}
        fullWidth
        onClose={() => setOpenDescription(null)}
      >
        <DialogContent>
          <div className={classes.modal}>
            <img className={classes.modalImage} src={data.image} />
            <div>
              <div
                className={classes.descriptionModal}
                dangerouslySetInnerHTML={{ __html: data.descriptionModal }}
              />
              <div
                style={{ justifyContent: 'flex-start' }}
                className={classes.prices}
              >
                <span className={classes.oldPrice}>
                  {getLocalStringNumber(data.oldPrice)}&nbsp;р.
                </span>
                <span className={classes.newPrice}>
                  <img src="/assets/2024/price.svg" />
                  <span>{getLocalStringNumber(data.price)}&nbsp;р.</span>
                </span>
              </div>
              {data.soldout && !clearSoldOut ? null : (
                <div className={classes.buttons}>
                  {data.buttons.map((button, index) => (
                    <React.Fragment key={index}>
                      {getButtons(button)}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : null
}

export { CardSmall }
