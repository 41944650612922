import React from 'react'

import { BUTTONS_COURSES } from '../../buttons.const'

import { useBannerStyles } from './Banner.styled'

interface IProps {
  deleteArr: string[]
  handleScroll: (scroll: string) => void
}

const Banner = ({ deleteArr, handleScroll }: IProps) => {
  const classes = useBannerStyles()

  const filterCards = (items) => {
    return items.filter((cartItem) => !deleteArr.includes(cartItem.key))
  }

  return (
    <div className={classes.container}>
      <img
        className={classes.giftLeft}
        src="/assets/2024_forAll/leftStar.png"
      />
      <p className={classes.text}>Лучшие предложения и скидки</p>
      <div className={classes.buttons}>
        {filterCards(BUTTONS_COURSES).map((button, index) => (
          <button
            key={index}
            className={classes.buttonDiscount}
            onClick={() => handleScroll(button.id)}
          >
            {button.name}
          </button>
        ))}
      </div>
      <img
        className={classes.giftRight}
        src="/assets/2024_forAll/rightStar.png"
      />
    </div>
  )
}

export { Banner }
