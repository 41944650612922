import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useCardStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      background: '#eeeeee',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      marginTop: 20,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#fff',
      padding: '18px 15px',
      borderRadius: '0 0 20px 20px'
    },
    image: {
      position: 'relative',
      top: '-40px',
      marginBottom: '-63px',
      aspectRatio: '1 / 0.9',
      '& > img': {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        display: 'block',
        margin: '0px auto',
        width: 'auto',
        maxHeight: '100%'
      }
    },
    title: {
      color: '#101010',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '120%',
      marginTop: 0,
      marginBottom: 4
    },
    description: {
      color: '#101010',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '120%',
      marginTop: 0,
      marginBottom: 10
    },
    contentContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end'
    },
    more: {
      color: '#EC1656',
      textDecoration: 'underline',
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '10px',
      background: 'none',
      border: 'none',
      padding: '0px',
      cursor: 'pointer'
    },
    prices: {
      marginTop: 13,
      padding: '0px 6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
      width: '100%',
      marginBottom: '12px'
    },
    oldPrice: {
      position: 'relative',
      color: '#191919',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '135%',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 21,
        left: 4,
        width: '100%',
        height: '100%',
        borderTop: '2px solid red',
        transform: 'rotate(-12deg)',
        transformOrigin: 'left'
      }
    },
    newPrice: {
      position: 'relative',
      '& > span': {
        position: 'absolute',
        left: 22,
        top: 8,
        color: '#242424',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '125%'
      }
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      width: '100%'
    },
    buttonDiscount: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      boxShadow: '0px 2.557px 0px 0px #A43191',
      background: '#EC1656',
      color: '#fff',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '17px',
      height: '45px'
    },
    buttonCart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
      width: '100%',
      borderRadius: 8,
      cursor: 'pointer',
      border: '2px solid #EC1656',
      background: 'transparent',
      color: '#EC1656',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '17px',
      height: '45px'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column'
      }
    },
    modalImage: {
      maxWidth: '300px',
      minWidth: '300px',
      [theme.breakpoints.down(600)]: {
        minWidth: 'auto'
      }
    },
    descriptionModal: {
      minWidth: '400px',
      marginBottom: 20,
      '& h2': {
        fontSize: 24,
        marginTop: 0
      },
      '& h3': {
        fontSize: 18,
        marginTop: 20,
        marginBottom: 0
      },
      '& ul': {
        padding: '0px 14px',
        margin: '10px 0 10px'
      },
      '& span': {
        display: 'block',
        color: '#898989'
      },
      [theme.breakpoints.down(900)]: {
        minWidth: 'auto'
      }
    },

    soldout: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& > img': {
        maxWidth: '250px',
        [theme.breakpoints.down(600)]: {
          maxWidth: '200px'
        }
      }
    }
  })
)
