export interface IItemCart {
  id: string
  key: string
  name: string
  price: number
  quantity: number
}

export const cartKey = 'profinansy_cart_all1'
export const eventCart = 'updateCart'

export const addToCart = (
  item: Omit<IItemCart, 'quantity'>,
  removeItems?: string[]
) => {
  const cart: IItemCart[] = JSON.parse(localStorage.getItem(cartKey) || '[]')

  const existingItem = cart.findIndex((cartItem) => cartItem.key === item.key)

  if (existingItem !== -1) {
    cart[existingItem] = { ...cart[existingItem] }
  } else {
    cart.push({ ...item, quantity: 1 })
  }

  const removeItemsFromArray = () => {
    return cart.filter((cartItem) => !removeItems.includes(cartItem.key))
  }

  localStorage.setItem(
    cartKey,
    JSON.stringify(removeItems ? removeItemsFromArray() : cart)
  )
  window.dispatchEvent(new Event(eventCart))
}

export const removeFromCart = (key: string) => {
  const cart: IItemCart[] = JSON.parse(localStorage.getItem(cartKey) || '[]')

  const updatedCart = cart.filter((cartItem) => cartItem.key !== key)

  localStorage.setItem(cartKey, JSON.stringify(updatedCart))
  window.dispatchEvent(new Event(eventCart))
}

export const changeQuantity = (
  key: string,
  operation: 'increment' | 'decrement'
) => {
  const cart: IItemCart[] = JSON.parse(localStorage.getItem(cartKey) || '[]')

  const updatedCart = cart.map((cartItem) =>
    cartItem.key === key
      ? {
          ...cartItem,
          quantity:
            operation === 'increment'
              ? cartItem.quantity + 1
              : cartItem.quantity - 1
        }
      : cartItem
  )

  localStorage.setItem(cartKey, JSON.stringify(updatedCart))
  window.dispatchEvent(new Event(eventCart))
}
