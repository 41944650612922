import React from 'react'

import { CARDS } from '../../cardsSmall.const'
import { CardSmall } from '../CardSmall/CardSmall'

import { useBannerStyles } from './Friday3Block.styled'

interface IProps {
  deleteArr: string[]
  clearSoldOut?: boolean
}

const Friday3Block = ({ deleteArr, clearSoldOut }: IProps) => {
  const classes = useBannerStyles()

  const filterCards = (items) => {
    return items.filter((cartItem) => !deleteArr.includes(cartItem.key))
  }

  return (
    <div className={classes.pinkBlock}>
      <img className={classes.banner} src="/assets/2024_forAll/banner3.png" />

      <div className={classes.cardsSmall}>
        {filterCards(CARDS).map((card, index) => (
          <CardSmall data={card} key={index} clearSoldOut={clearSoldOut} />
        ))}
      </div>
    </div>
  )
}

export { Friday3Block }
