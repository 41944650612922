import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useBannerStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    pinkBlock: {
      width: '100%',
      backgroundColor: '#000',
      padding: '40px 24px'
    },
    banner: {
      display: 'block',
      maxWidth: 1200,
      width: '100%',
      margin: '0 auto'
    },
    text: {
      maxWidth: 1215,
      textAlign: 'center',
      margin: '0px auto',
      marginTop: '40px',
      fontSize: '48px',
      lineHeight: '132%',
      fontWeight: 900,
      textTransform: 'uppercase',
      color: '#fff',
      '& > span': {
        color: '#FFCA37'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '30px'
      }
    },
    cardsSmall: {
      maxWidth: 1248,
      padding: '0px 24px',
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: 'repeat(3, 1fr)'
      },
      [theme.breakpoints.down(950)]: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      },
      [theme.breakpoints.down(650)]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    }
  })
)
