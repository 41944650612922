import { makeRequest } from './makeRequest'

const getUser = (
  body: any,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/getUser`,
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError
  )
const auth = (
  body: any,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(`/auth`, body, 'form', needNotify, ignoreError, false, canError)
const getState = (
  body: any,
  ukey: string,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/getState` + (ukey ? `?ukey=${ukey}` : ''),
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError,
    false,
    'https://live-api.profinansy.ru/api'
  )

const banUser = (
  body: any,
  ukey: string,
  name: string,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/banUser` + (ukey ? `?ukey=${ukey}` : '') + (name ? `&name=${name}` : ''),
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError
  )
const getComments = (
  body: any,
  ukey: string,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/getComments` + (ukey ? `?ukey=${ukey}` : ''),
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError,
    false,
    'https://live-api.profinansy.ru/api'
  )
const setComment = (
  body: any,
  ukey: string,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/setComment` + (ukey ? `?ukey=${ukey}` : ''),
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError,
    false,
    'https://live-api.profinansy.ru/api'
  )

const getPoll = (
  body: any,
  ukey: string,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/getPoll` + (ukey ? `?ukey=${ukey}` : ''),
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError
  )
const answerPoll = (
  body: any,
  ukey: string,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/answerPoll` + (ukey ? `?ukey=${ukey}` : ''),
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError
  )

const getAverage = (
  body: any,
  ukey: string,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/getAverage` + (ukey ? `?ukey=${ukey}` : ''),
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError
  )
const answerAverage = (
  body: any,
  ukey: string,
  needNotify?: boolean,
  ignoreError?: boolean,
  canError?: Array<any>
) =>
  makeRequest(
    `/answerAverage` + (ukey ? `?ukey=${ukey}` : ''),
    body,
    'form',
    needNotify,
    ignoreError,
    false,
    canError
  )

export { getAverage }
export { answerAverage }
export { getPoll }
export { answerPoll }
export { setComment }
export { getComments }
export { getUser }
export { auth }
export { getState }
export { banUser }
