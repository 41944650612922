import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useBannerStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    pinkBlock: {
      width: '100%',
      backgroundColor: '#000',
      padding: '40px 24px'
    },
    banner: {
      display: 'block',
      maxWidth: 1200,
      width: '100%',
      margin: '0 auto'
    },
    text2: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1000,
      textAlign: 'center',
      margin: '32px auto',
      fontSize: '22px',
      lineHeight: '120%',
      fontWeight: 700,
      color: '#FFF',
      [theme.breakpoints.down(600)]: {
        fontSize: '18px'
      }
    },
    cardsMedium: {
      maxWidth: 1248,
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(750)]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    cardsMedium2: {
      maxWidth: 1248,
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(750)]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },

    cardsBig: {
      marginTop: 70,
      display: 'flex',
      flexDirection: 'column',
      gap: 70,
      maxWidth: 1200,
      width: '100%',
      margin: '0 auto'
    }
  })
)
