import QueryString from 'qs'
import React, { useEffect, useRef, useState } from 'react'

import { Timer } from '../BlackFriday/components/Timer/Timer'

import { useBlackStyles } from './BlackFriday.styled'
import { Banner } from './components/Banner/Banner'
import { Cart } from './components/Cart/Cart'
import { Friday1Block } from './components/Friday1Block/Friday1Block'
import { Friday2Block } from './components/Friday2Block/Friday2Block'
import { Friday3Block } from './components/Friday3Block/Friday3Block'
import { Friday4Block } from './components/Friday4Block/Friday4Block'
import { Friday5Block } from './components/Friday5Block/Friday5Block'

interface IProps {
  deleteArr: string[]
  version?: string
}

const BlackFriday = ({ deleteArr, version }: IProps) => {
  const [clearSoldOut, setClearSoldOut] = useState(false)
  const classes = useBlackStyles()

  useEffect(() => {
    const storedVersion = localStorage.getItem('version')

    if (storedVersion !== `${version}`) {
      localStorage.setItem('version', version)
      window.location.reload()
    }
  }, [version])

  useEffect(() => {
    const parse = QueryString.parse(window.location.search || '', {
      ignoreQueryPrefix: true
    }) as any

    if (parse.clear === 'soldout') {
      setClearSoldOut(true)
    }
  }, [])

  const getUrl = () => {
    const today = new Date()
    const day = today.getDate()
    const month = today.getMonth() + 1
    const year = today.getFullYear()

    if (year === 2024) {
      if (month === 11 && day === 29) {
        return 'https://investmafk.ru/zayavka/blackfriday1'
      } else if (month === 11 && day === 30) {
        return 'https://investmafk.ru/zayavka/blackfriday2'
      } else if (month === 12 && day === 1) {
        return 'https://investmafk.ru/zayavka/blackfriday3'
      }
    }

    return null
  }

  const friday1Ref = useRef<HTMLDivElement | null>(null)
  const friday2Ref = useRef<HTMLDivElement | null>(null)

  const handleScroll = (id: string) => {
    if (id === '1' && friday1Ref.current) {
      friday1Ref.current.scrollIntoView({ behavior: 'smooth' })
    } else if (id === '2' && friday2Ref.current) {
      friday2Ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div>
      {deleteArr?.some(
        (item) =>
          item === 'friday_translation' || item === 'friday_translation_button'
      ) && (
        <>
          <div className={classes.buttons}>
            {deleteArr.some((item) => item === 'friday_translation_button') && (
              <a href={getUrl()} target="_blank" rel="noopener noreferrer">
                <button>Забрать подарки и участвовать в розыгрыше</button>
              </a>
            )}
            <a
              href="https://clck.ru/3EdReW"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>Задать вопрос менеджеру</button>
            </a>
          </div>

          <Timer small />
        </>
      )}

      {clearSoldOut
        ? deleteArr?.some((item) => item === 'friday_buttons') && (
            <Banner deleteArr={deleteArr} handleScroll={handleScroll} />
          )
        : null}

      {deleteArr?.some((item) => item === 'friday_1') && (
        <div ref={friday1Ref}>
          <Friday1Block deleteArr={deleteArr} clearSoldOut={clearSoldOut} />
        </div>
      )}

      {deleteArr?.some((item) => item === 'friday_2') && (
        <div ref={friday2Ref}>
          <Friday2Block deleteArr={deleteArr} clearSoldOut={clearSoldOut} />
        </div>
      )}

      {clearSoldOut
        ? deleteArr?.some((item) => item === 'friday_3') && (
            <Friday3Block deleteArr={deleteArr} clearSoldOut={clearSoldOut} />
          )
        : null}

      {clearSoldOut
        ? deleteArr?.some((item) => item === 'friday_4') && (
            <Friday4Block deleteArr={deleteArr} clearSoldOut={clearSoldOut} />
          )
        : null}

      {clearSoldOut
        ? deleteArr?.some((item) => item === 'friday_5') && (
            <Friday5Block deleteArr={deleteArr} clearSoldOut={clearSoldOut} />
          )
        : null}

      <Cart />
      <a
        href="https://clck.ru/3EcvnX"
        target="_blank"
        className={classes.whatsapp}
      >
        <img src="/assets/black-friday/whatsapp.svg" alt="whatsapp" />
      </a>
    </div>
  )
}

export { BlackFriday }
