export const BUTTONS_COURSES = [
  {
    id: '1',
    name: 'Для тех, кто хочет начать инвестировать'
  },
  {
    id: '2',
    name: 'Для тех, кто уже инвестирует'
  }
]
