import React from 'react'

import { CARDS_MEDIUM3 } from '../../cardsMedium.const'
import { CardMedium } from '../CardsMedium/CardMedium'

import { useBannerStyles } from './Friday4Block.styled'

interface IProps {
  deleteArr: string[]
  clearSoldOut?: boolean
}

const Friday4Block = ({ deleteArr, clearSoldOut }: IProps) => {
  const classes = useBannerStyles()

  const filterCards = (items) => {
    return items.filter((cartItem) => !deleteArr.includes(cartItem.key))
  }

  return (
    <div className={classes.pinkBlock}>
      <img className={classes.banner} src="/assets/2024_forAll/banner4.png" />

      <p className={classes.text}>
        <span> Эти продукты можно купить</span> только на этой Черной Пятнице,
        только на этом сайте прямо сейчас! секретное предложение лично от Ольги
        Гогаладзе!
      </p>

      <div className={classes.cardsMedium}>
        {filterCards(CARDS_MEDIUM3).map((card, index) => (
          <CardMedium data={card} key={index} clearSoldOut={clearSoldOut} />
        ))}
      </div>
    </div>
  )
}

export { Friday4Block }
