import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useBlackStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '16px',
      maxWidth: '1040px',
      margin: '50px auto 20px auto',
      '& button': {
        height: '80px',
        borderRadius: 8,
        width: '1036px',
        fontSize: '24px',
        fontWeight: 700,
        cursor: 'pointer',
        border: 'none',

        [theme.breakpoints.down(1060)]: {
          maxWidth: '700px'
        },
        [theme.breakpoints.down(720)]: {
          maxWidth: '360px'
        }
      },
      '& a:first-child button': {
        cursor: 'pointer',
        backgroundColor: '#FFCA37'
      },
      '& a:last-child button': {
        cursor: 'pointer',
        backgroundColor: '#030303',
        color: '#FFCA37',
        border: '2px solid #FFCA37'
      }
    },
    giftButton: {
      height: '60px',
      borderRadius: 8,
      padding: '4px 28px',
      fontSize: '16px',
      fontWeight: 600,
      cursor: 'pointer',
      border: 'none',
      backgroundColor: '#E4F344',
      display: 'block',
      margin: '0 auto 20px auto'
    },
    text: {
      padding: '0px 24px',
      maxWidth: 800,
      textAlign: 'center',
      margin: '0px auto',
      marginTop: '40px',
      fontSize: '28px',
      lineHeight: '132%',
      fontWeight: 700,
      color: '#fff',
      '& > span': {
        color: '#FE21E0'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '20px'
      }
    },
    cardsSmall: {
      maxWidth: 1248,
      padding: '0px 24px',
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: 'repeat(3, 1fr)'
      },
      [theme.breakpoints.down(950)]: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      },
      [theme.breakpoints.down(650)]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },

    banner: {
      display: 'block',
      maxWidth: 1200,
      width: '100%',
      margin: '0 auto'
    },

    blackBlock: {
      width: '100%',
      backgroundColor: '#0C0C0C',
      padding: '40px 24px'
    },
    superText: {
      marginTop: 40,
      maxWidth: 800,
      textAlign: 'center',
      margin: '0px auto',
      fontSize: '30px',
      lineHeight: '132%',
      fontWeight: 900,
      color: '#fff',
      '& > span': {
        color: '#E4F344'
      },
      [theme.breakpoints.down(600)]: {
        marginTop: 20,
        fontSize: '24px'
      }
    },
    cardsSuper: {
      maxWidth: 900,
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(900)]: {
        maxWidth: 500,
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    not_russia: {
      marginTop: 40,
      maxWidth: 800,
      textAlign: 'center',
      margin: '0px auto',
      fontSize: '56px',
      lineHeight: '132%',
      fontWeight: 900,
      color: '#fff',
      '& > span': {
        color: '#FE21E0'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '36px'
      }
    },

    whatsapp: {
      position: 'fixed',
      bottom: 55,
      right: 20,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#242424',
      fontSize: '12px',
      textDecoration: 'none',
      zIndex: 10,

      '& > img': {
        marginTop: 6,
        maxWidth: 120
      }
    }
  })
)
