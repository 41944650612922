import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useTimerStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    smallTimer: {
      borderRadius: '15px',
      border: '1px solid #636363',
      background: 'rgba(28, 29, 33, 0.70)',
      boxShadow: '0px 1.92px 1.92px 0px rgba(0, 0, 0, 0.25)',
      padding: '8px 32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 40,
      position: 'relative',
      width: '100%',
      maxWidth: 797,
      margin: '59px auto',

      [theme.breakpoints.down(900)]: {
        maxWidth: 597,
        gap: 20
      },

      [theme.breakpoints.down(720)]: {
        flexDirection: 'column',
        maxWidth: 300
      }
    },
    timer: {
      borderRadius: '15px',
      border: '1px solid #636363',
      background: 'rgba(28, 29, 33, 0.70)',
      boxShadow: '0px 1.92px 1.92px 0px rgba(0, 0, 0, 0.25)',
      padding: '32px 86px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 50,
      position: 'relative',
      width: '100%',
      maxWidth: 1088,
      margin: '0 auto',

      [theme.breakpoints.down(1170)]: {
        maxWidth: 865,
        gap: 30
      },
      [theme.breakpoints.down(950)]: {
        flexDirection: 'column',
        padding: '12px',
        gap: 20
      },
      [theme.breakpoints.down(500)]: {
        maxWidth: 335,
        padding: '10px'
      }
    },

    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    text: {
      color: '#898989',
      textAlign: 'center',
      fontFamily: '"Russo One"',
      fontSize: '25px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '125%',
      textTransform: 'uppercase',
      [theme.breakpoints.down(1000)]: {
        fontSize: '24px'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '12px',
        paddingBottom: 10
      }
    },
    textSmall: {
      color: '#898989',
      textAlign: 'center',
      fontFamily: '"Russo One"',
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '125%',
      textTransform: 'uppercase',
      [theme.breakpoints.down(1000)]: {
        fontSize: '24px'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '16px'
      }
    },
    time: {
      color: '#FFCA37',
      textAlign: 'center',
      fontSize: '45px',
      fontStyle: 'normal',

      fontWeight: 700,
      lineHeight: '125%',

      [theme.breakpoints.down(1000)]: {
        fontSize: '35px'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '20px'
      }
    },
    line: {
      width: '1px',
      height: '109px',
      background:
        'linear-gradient(90deg, #1B1B1E 3.23%, #FFF 54.16%, #1B1B1F 100%)',

      [theme.breakpoints.down(950)]: {
        display: 'none'
      }
    },

    clockLeft: {
      position: 'absolute',
      bottom: -50,
      left: -58,
      maxWidth: 128
    },
    clockRight: {
      position: 'absolute',
      top: -22,
      right: -45,
      maxWidth: 115
    },

    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: 12
    },

    info: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
      width: 373,
      [theme.breakpoints.down(600)]: {
        width: 273
      }
    },
    dashed: {
      display: 'flex',
      borderBottom: '1px dashed #FFF',
      width: '100%',
      minWidth: 120,
      padding: '5px',

      [theme.breakpoints.down(550)]: {
        minWidth: 50
      }
    },
    title: {
      color: '#FFF',
      fontFamily: 'Manrope',
      fontSize: '14.083px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '130%',
      whiteSpace: 'nowrap'
    },
    value: {
      color: '#FFCA37',
      textAlign: 'right',
      fontFamily: 'Manrope',
      fontSize: '15.844px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '130%',
      whiteSpace: 'nowrap'
    }
  })
)
