import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useCartStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    cart: {
      position: 'fixed',
      bottom: 40,
      right: 80,
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px',
      border: 'none',
      background: 'blue',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      zIndex: 100000,
      '&:hover': {
        backgroundColor: '#FE21E0'
      },
      '& > img': {
        width: '30px'
      },
      [theme.breakpoints.down(800)]: {
        top: 'auto',
        bottom: 50
      }
    },
    countItems: {
      position: 'absolute',
      bottom: -5,
      right: -5,
      borderRadius: '100%',
      minWidth: '30px',
      height: '30px',
      padding: 8,
      background: 'red',
      color: '#fff',
      fontWeight: 600
    },
    title: {
      fontSize: '24px',
      padding: 0,
      marginTop: 0
    },
    items: {
      borderTop: '1px solid #eee',
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
      borderBottom: '1px solid #eee',
      padding: '8px 0px'
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 0px',
      fontSize: '16px',
      fontWeight: 500,
      '&:not(:last-child)': {
        borderBottom: '1px dashed #eee'
      },
      [theme.breakpoints.down(600)]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    right: {
      display: 'flex',
      gap: 8,
      marginLeft: '24px',
      alignItems: 'center',
      '& div:last-child': {
        textAlign: 'right',
        minWidth: '150px'
      },
      [theme.breakpoints.down(600)]: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 20,
        marginTop: 8,
        marginLeft: '0px',
        '& div:last-child': {
          textAlign: 'right',
          minWidth: 'auto'
        }
      }
    },
    bottom: {
      paddingTop: '20px',
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '24px',
      fontWeight: 600
    },
    buttons: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 12
    },
    button: {
      width: '100%',
      height: '50px',
      background: 'blue',
      color: '#fff',
      fontSize: '18px',
      border: 'none',
      borderRadius: 12,
      display: 'block',
      marginTop: 20,
      marginLeft: 'auto',
      cursor: 'pointer'
    },
    buttonTinkoff: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      background: '#FFDD2D',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12
    },
    tinkoffText: {
      fontSize: 16,
      color: '#333333',
      fontWeight: 700,
      display: 'block',
      textAlign: 'left'
    },
    buttonSber: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      background:
        'linear-gradient(90deg, #529E46 0%, #68D53D 50.7%, #D1E648 100%)',
      height: 45,
      color: '#fff',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12,
      '& > img': {
        maxWidth: '30px'
      }
    },
    buttonOther: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      background: '#DDA0DD',
      height: 45,
      color: '#000',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonOther1: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      background: '#ADD8E6',
      height: 45,
      color: '#000',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonOther2: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      background: '#FFE4B5',
      height: 45,
      color: '#000',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonOther3: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      background: '#3CB371',
      height: 45,
      color: '#000',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    price: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 12
    },
    delete: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '4px 8px',
      borderRadius: '6px',
      background: 'transparent',
      border: '1px solid #ddd',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  })
)
