import { Dialog, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'

import { getLocalStringNumber } from '../../../../utils/getLocalStringNamber'
import { addToCart } from '../../utils'
import { BigCardData } from '../CardsBig/CardsBig.types'

import { useCardStyles } from './CardMedium.styled'

interface IProps {
  data: BigCardData
  isBig?: boolean
  clearSoldOut?: boolean
}

const CardMedium = ({ data, isBig, clearSoldOut }: IProps) => {
  const classes = useCardStyles({ isBig })

  const [openDescription, setOpenDescription] = useState(false)

  const handleOpenCart = () => {
    addToCart({
      name: `${data.title} ${data.name}`,
      price: data.price,
      key: data.key,
      id: data.id
    })
  }

  const getButtons = (item) => {
    if (item.type === 'discount') {
      return (
        <button className={classes.buttonDiscount} onClick={handleOpenCart}>
          Оплатить со скидкой
        </button>
      )
    }
    if (item.type === 'cart') {
      return (
        <button className={classes.buttonCart} onClick={handleOpenCart}>
          <img src="/assets/2024_forAll/cartIcon.png" />
          Добавить в корзину
        </button>
      )
    }

    if (item.type === 'tinkoff')
      return (
        <button className={classes.buttonTinkoff} onClick={handleOpenCart}>
          <div>
            <span className={classes.tinkoffText}>Оформить в рассрочку</span>
            <span className={classes.tinkoffSubText}>
              {item.subtext || 'от 3 500 ₽ в месяц'}
            </span>
          </div>
        </button>
      )
    return null
  }

  return !data.soldout || clearSoldOut ? (
    <>
      <div className={classes.container}>
        <div className={classes.image}>
          <img src={data.image} />
        </div>

        <div className={classes.title}>
          <div className={classes.text}>
            <span style={{ color: '#EC1656' }}>{data.title}</span>
            <span>{data.name}</span>
          </div>
          <div className={classes.count}>
            {clearSoldOut
              ? `всего: ${data.count} мест`
              : data.amount === 'РАСПРОДАНО'
              ? 'РАСПРОДАНО'
              : `Осталось: ${data.amount} мест из ${data.count}`}
          </div>
        </div>

        <div className={classes.content}>
          {data.subTitle && (
            <div className={classes.subTitleContainer}>
              {data.subTitle.icon && <img src={data.subTitle.icon} />}
              <span className={classes.name}>{data.subTitle.name}</span>
            </div>
          )}
          {data.mainProducts && (
            <div className={classes.mainProducts}>
              {data.mainProducts?.map((item, index) => (
                <div className={classes.mainItem} key={index}>
                  <div className={classes.leftText}>
                    {item.icon && <img src={item.icon} />}
                    <span className={classes.name}>
                      {item.name}{' '}
                      {item.rightText && (
                        <span className={classes.rightText}>
                          {item.rightText}
                        </span>
                      )}
                    </span>
                  </div>
                  {item.price ? (
                    <span className={classes.price}>
                      {getLocalStringNumber(item.price)}&nbsp;р.
                    </span>
                  ) : (
                    <div className={classes.new}>
                      <img src="/assets/2024/price.svg" />
                      <span>Подарок</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          <ul className={classes.list}>
            {data.products.map((item, index) => (
              <li className={classes.listItem} key={index}>
                <span className={classes.name2}>
                  {item.icon ? (
                    <span style={{ marginRight: '5px' }}>
                      <img src={item.icon} />
                    </span>
                  ) : (
                    '• '
                  )}
                  {item.name}
                </span>
                {item.price ? (
                  <span className={classes.price}>
                    {getLocalStringNumber(item.price)}&nbsp;р.
                  </span>
                ) : (
                  <div className={classes.new}>
                    <img src="/assets/2024/price.svg" />
                    <span>Подарок</span>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className={classes.contentContainer}>
            <div className={classes.descBox}>
              <p className={classes.description}>{data.description}</p>
              <button
                className={classes.more}
                onClick={() => setOpenDescription(true)}
              >
                Подробнее о пакете
              </button>
            </div>
            <div className={classes.prices}>
              <span className={classes.oldPrice}>
                {getLocalStringNumber(data.oldPrice)}&nbsp;р.
              </span>
              <span className={classes.newPrice}>
                {getLocalStringNumber(data.price)}&nbsp;р.
              </span>
              <span className={classes.payCredit}>
                <img src="/assets/2024_forAll/price.png" />
                <span>{data.payCredit}</span>
              </span>
            </div>
          </div>
          {(data.soldout && !clearSoldOut) ||
          (data.amount === 'РАСПРОДАНО' && !clearSoldOut) ? (
            <div className={classes.soldout}>
              <img src="/assets/2024_forAll/soldout.png" />
            </div>
          ) : (
            <>
              <div className={classes.firstButton}>
                <React.Fragment>{getButtons(data.buttons[0])}</React.Fragment>
              </div>
              <div className={classes.buttons}>
                <React.Fragment>{getButtons(data.buttons[1])}</React.Fragment>
                <React.Fragment>{getButtons(data.buttons[2])}</React.Fragment>
              </div>
            </>
          )}
        </div>
      </div>

      <Dialog
        maxWidth="md"
        open={!!openDescription}
        fullWidth
        onClose={() => setOpenDescription(null)}
      >
        <DialogContent>
          <div className={classes.modal}>
            <img className={classes.modalImage} src={data.image} />
            <div>
              <div
                className={classes.descriptionModal}
                dangerouslySetInnerHTML={{ __html: data.descriptionModal }}
              />
              <div
                style={{ justifyContent: 'flex-start' }}
                className={classes.pricesForModal}
              >
                <span className={classes.oldPriceForModal}>
                  {getLocalStringNumber(data.oldPrice)}&nbsp;р.
                </span>
                <span className={classes.newPriceForModal}>
                  <img src="/assets/2024/price.svg" />
                  <span>{getLocalStringNumber(data.price)}&nbsp;р.</span>
                </span>
              </div>
              {(data.soldout && !clearSoldOut) ||
              (data.amount === 'РАСПРОДАНО' && !clearSoldOut) ? null : (
                <div className={classes.buttonsForModal}>
                  {data.buttons.map((button, index) => (
                    <React.Fragment key={index}>
                      {getButtons(button)}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : null
}

export { CardMedium }
