export const CARDS_MEDIUM = [
  {
    key: 'combo_3',
    id: '643338:22800,643390:6342,643391:858,640104:1,643326:1,640356:2897,643411:1',
    title: 'Пакет №3: ',
    name: 'Всё сам',
    count: 100,
    amount: 0,
    image: '/assets/2024_forAll/box3.png',
    soldout: true,
    description: 'Для тех, кто может разобраться во всём сам',
    mainProducts: [
      {
        name: 'Метод I Всё сам',
        price: 42000,
        rightText: '8 поток',
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      },
      {
        name: 'Метод II Всё сам',
        price: 42000,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      },
      {
        name: 'Конструктор финансового плана',
        price: 70000,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      }
    ],
    subTitle: null,

    products: [
      {
        name: 'Установочная консультация по вашим целям',
        price: 35000,
        icon: null
      },

      {
        name: 'Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе',
        price: 7990,
        icon: null
      },

      {
        name: 'Печатный учебник Инвестиции доступны всем',
        price: null,
        icon: '/assets/2024_forAll/giftIcon.png'
      }
    ],

    price: 32900,
    oldPrice: 196990,
    payCredit: 'от 2 740 р./ мес. ',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 2 740 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Пакет №3. Всё сам </h2>' +
      '<p><b>Метод управления деньгами с печатным учебником</b> - это курс по личным финансам и инвестициям, который выведет вас на новый уровень отношений с вашими деньгами всего за 3 месяца.\n</p>' +
      '<h3>Обучение состоит из 3 блоков:\n</h3>' +
      '<ul>' +
      '<li>Личные финансы — узнаете, как правильно вести бюджет и управлять деньгами. Денег будет хватать всегда и на все!\n</li>' +
      '<li>Инвестиции — поймете, куда прибыльно вкладывать деньги. Ваши деньги будут приносить пассивный доход!\n</li>' +
      '<li>Доход и карьера — узнаете, как вырасти в карьере и получать больше, работая меньше. У вас появится еще больше свободных денег, которые вы сможете откладывать!\n</li>' +
      '</ul>' +
      '<h4>Доступ к курсу у вас останется навсегда!\n</h4>' +
      '<h5>Внимание! Тариф БЕЗ обратной связи\n</h5>' +
      '<h5>Доступ к конструктору финансового плана БЕЗ проверки портфеля\n</h5>' +
      '<p><b>Установочная консультация по вашим финансовым целям</b> - сформулируйте финансовую цель на обучение вместе со специалистом команды pro.finansy \n</p>' +
      '<p><b>Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе</b> - научит пользоваться криптовалютами даже тех, кто с ними совсем не знаком. Из уроков вы узнаете: \n</p>' +
      '<ul>' +
      '<li>что такое криптовалюта, и какой она бывает\n</li>' +
      '<li>как используют криптовалюту в современном мире\n</li>' +
      '<li>Доход и карьера —где и как безопасно покупать, продавать, хранить и переводить криптовалюту\n</li>' +
      '</ul>' +
      '<p>В курс включены уроки с теорией и практикой, а также бонусные уроки и методическое пособие. Всё это позволит вам начать использовать валюту 21 века уже во время обучения. \n</p>'
  },
  {
    key: 'combo_4',
    id: '643320:29900',
    title: 'Пакет №4: ',
    name: 'Все экспресс-курсы',
    count: 500,
    amount: 'РАСПРОДАНО',
    image: '/assets/2024_forAll/box4.png',

    soldout: false,
    description: null,
    mainProducts: null,
    subTitle: {
      name: '12 курсов по финансам и инвестициям:',
      icon: '/assets/2024_forAll/books.png'
    },
    products: [
      {
        name: 'Курс Аналитика личных финансов',
        price: 7000,
        icon: null
      },

      {
        name: 'Золотой курс Ольги Гогаладзе',
        price: 16990,
        icon: null
      },
      {
        name: 'Курс Дивидендная зарплата',
        price: 20990,
        icon: null
      },
      {
        name: 'Курс по трейдинг-стратегии на инсайдерских сделках SMART money',
        price: 7990,
        icon: null
      },
      {
        name: 'Курс Как и зачем инвестировать в нефть',
        price: 16990,
        icon: null
      },
      {
        name: 'Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе',
        price: 7990,
        icon: null
      },
      {
        name: 'Курс Как легко платить налоги от инвестиций',
        price: 16990,
        icon: null
      },
      {
        name: 'Мастер-класс Облигационная зарплата от Ольги Гогаладзе',
        price: 7900,
        icon: null
      },
      {
        name: 'Экспресс-курс по экономике',
        price: 12990,
        icon: null
      },
      {
        name: 'Марафон инвест-идей на 2024–2025 годы',
        price: 7900,
        icon: null
      },
      {
        name: 'Курс по техническому анализу I ступень',
        price: 7990,
        icon: null
      },
      {
        name: 'Курс по техническому анализу II ступень',
        price: 7990,
        icon: null
      }
    ],

    price: 29900,
    oldPrice: 139710,
    payCredit: 'от 2 491 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 2 491 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Пакет №4: Все экспресс-курсы</h2>' +
      '<p><b>12 экспресс- курсов </b> - по финансам и инвестициям для углубленного изучения отдельных тем. От аналитики личных финансов до трейдинг-стратегий — всё, что нужно, чтобы увеличить доходность ваших инвестиций и сформировать пассивный доход. \n</p>' +
      '<ul>' +
      '<li>Курс Аналитика личных финансов\n</li>' +
      '<li>Курс Дивидендная зарплата\n</li>' +
      '<li>Мастер-класс - Облигационная зарплата от Ольги Гогаладзе\n</li>' +
      '<li>Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе\n</li>' +
      '<li>Марафон инвест-идей на 2024–2025 годы. Запись\n</li>' +
      '<li>Курс по техническому анализу I ступень\n</li>' +
      '<li>Курс по техническому анализу II ступень\n</li>' +
      '<li>Курс по трейдинг-стратегии на инсайдерских сделках SMART money\n</li>' +
      '<li>Золотой курс Ольги Гогаладзе\n</li>' +
      '<li>Курс Как и зачем инвестировать в нефть\n</li>' +
      '<li>Курс Как легко платить налоги от инвестиций\n</li>' +
      '<li>Экспресс-курс по экономике\n</li>' +
      '</ul>' +
      '<h4>Доступ навсегда \n</h4>'
  }
]

export const CARDS_MEDIUM2 = [
  {
    key: 'combo_6',
    id: '451438:22990,643321:14000',
    title: 'Пакет №6: ',
    name: 'Богатый инвестор: участие в клубе инвесторов НАВСЕГДА+ 28 курсов по финансам и инвестициям',
    count: 50,
    amount: 3,
    image: '/assets/2024_forAll/box6.png',

    soldout: false,
    description: null,
    mainProducts: [
      {
        name: 'Клуб премиум навсегда',
        price: 69900,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      }
    ],
    subTitle: null,

    products: [
      {
        name: 'Все 22 курса клуба за все время',
        price: null,
        icon: null
      },

      {
        name: 'Мастер-класс Женские деньги от Ольги Гогаладзе',
        price: 7900,
        icon: null
      },

      {
        name: 'Марафон инвест-идей на 2024–2025 годы',
        price: 16990,
        icon: null
      },

      {
        name: 'Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе',
        price: 7990,
        icon: null
      },
      {
        name: 'Курс Дивидендная зарплата',
        price: 20990,
        icon: null
      },
      {
        name: 'Мастер-класс Облигационная зарплата от Ольги Гогаладзе',
        price: 7990,
        icon: null
      },
      {
        name: 'Налоги 2024. Самое полное интерактивное пособие по налоговым вычетам',
        price: null,
        icon: null
      }
    ],

    price: 36990,
    oldPrice: 131670,
    payCredit: 'от 3 082 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 3 082 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h3>Пакет№6. Богатый инвестор: участие в клубе инвесторов НАВСЕГДА+ 28 курсов по финансам и инвестициям</h3>' +
      '<p><b>Клуб pro.finansy</b> - это сообщество, где приумножают деньги. Получайте свежую и точную аналитику по фондовому рынку. Следите за сделками профессионального трейдера и зарабатывайте вместе с ним. Общайтесь с другими инвесторами и перенимайте их опыт. Задавайте вопросы финансовым консультантам и не тратьте время на самостоятельный поиск ответа. А еще мы встречаемся вместе вживую и ездим в путешествия\n</p>' +
      '<h3>Что получают участники клуба pro.finansy:\n</h3>' +
      '<ul>' +
      '<li>Закрытый телеграм-канал с новостями и аналитикой\n</li>' +
      '<li>Личный кабинет участника клуба в отдельном приложении\n</li>' +
      '<li>Ежемесячный инвест комитет: куда пойдут рынки в этом месяце\n</li>' +
      '<li>Еженедельный инвест апдейт: за чем следить на неделе и разбор новостей\n</li>' +
      '<li>Рубрика «Выбор pro.finansy» — список лучших акций из всех отраслей.\n</li>' +
      '<li>Реальные сделки в реальном портфеле. С аналитикой. Точкой входа и выхода.\n</li>' +
      '<li>Аналитические разборы акций в pdf на 15-20 страниц\n</li>' +
      '<li>Эфиры «Американские горки»: разбор фондового рынка США\n</li>' +
      '<li>Экономический календарь: за чем следить инвестору и как на этом заработать\n</li>' +
      '</ul>' +
      '<h3>В пакет включено:\n</h3>' +
      '<ul>' +
      '<li>резидентство в клубе pro.finansy НАВСЕГДА\n</li>' +
      '<li>отдельное приложение для резидентов клуба\n</li>' +
      '<li>22 продвинутых курса по инвестициям для резидентов Клуба\n</li>' +
      '<li>Мастер-класс Ольги Гогаладзе Женские деньги\n</li>' +
      '<li>Марафон инвест-идей на 2024–2025 годы. Запись\n</li>' +
      '<li>Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе\n</li>' +
      '<li>Курс Дивидендная зарплата\n</li>' +
      '<li>Мастер-класс - Облигационная зарплата от Ольги Гогаладзе\n</li>' +
      '<li>Налоги 2024. Самое полное интерактивное пособие по налоговым вычетам\n</li>' +
      '</ul>'
  },
  {
    key: 'combo_7',
    id: '192175:21990,643321:14000',
    title: 'Пакет №7: ',
    name: 'Умный инвестор: подписка на аналитический сервис для инвесторов profinansy.ru навсегда+ 6 курсов по финансам и инвестициям',
    count: 50,
    amount: 'РАСПРОДАНО',
    image: '/assets/2024_forAll/box7.png',

    soldout: false,
    description: null,
    mainProducts: [
      {
        name: 'Подписка на profinansy.ru навсегда',
        price: 64900,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      }
    ],
    subTitle: null,
    products: [
      {
        name: 'Мастер-класс Женские деньги от Ольги Гогаладзе',
        price: 7900,
        icon: null
      },

      {
        name: 'Марафон инвест-идей на 2024–2025 годы',
        price: 16990,
        icon: null
      },
      {
        name: 'Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе',
        price: 7990,
        icon: null
      },
      {
        name: 'Курс Дивидендная зарплата',
        price: 20990,
        icon: null
      },
      {
        name: 'Мастер-класс Облигационная зарплата от Ольги Гогаладзе',
        price: 7900,
        icon: null
      },
      {
        name: 'Налоги 2024. Самое полное интерактивное пособие по налоговым вычетам',
        price: null,
        icon: null
      }
    ],

    price: 35990,
    oldPrice: 126670,
    payCredit: 'от 2 999 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 2 999 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h3>Пакет №7 Умный инвестор: подписка на аналитический сервис для инвесторов profinansy.ru навсегда+ 6 курсов по финансам и инвестициям</h3>' +
      '<p><b>Premium-подписка на profinansy.ru</b> - позволит находить доходные идеи быстро и легко. Все надёжные инструменты для поиска ценных бумаг всегда будут у вас под рукой \n</p>' +
      '<p>Сайт подходит как начинающим, так и опытным инвесторам. Вам станут доступны больше 10 сервисов в одном месте. Пользуйтесь всеми возможностями через сайт или приложение pro.finansy.\n</p>' +
      '<h3>Чтобы находить доходные идеи, вам не придётся тратить много времени. Достаточно иметь простые и надёжные инструменты под рукой. Вы найдёте их на сайте profinansy.ru:\n</h3>' +
      '<ul>' +
      '<li>Обзор рынка на 360 градусов: котировки, показатели, события\n</li>' +
      '<li>Ведение инвестиционных портфелей и аналитика по ним в одном месте\n</li>' +
      '<li>Доступ к самой полной базе инвестиционных инструментов\n</li>' +
      '<li>Аналитика по инвестиционным инструментам и отчетность за 10 лет\n</li>' +
      '<li>Сервис расчета пассивного дохода от облигаций\n</li>' +
      '<li>Готовые фильтры быстрого подбора инвестиций\n</li>' +
      '<li>Календарь всех событий фондового рынка\n</li>' +
      '</ul>' +
      '<h3>В пакет включено:\n</h3>' +
      '<ul>' +
      '<li>Premium- доступ НАВСЕГДА к приложению pro.finansy и аналитическому сервису для инвесторов profinansy.ru\n</li>' +
      '<li>Мастер-класс Ольги Гогаладзе Женские деньги\n</li>' +
      '<li>Марафон инвест-идей на 2024–2025 годы. Запись\n</li>' +
      '<li>Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе\n</li>' +
      '<li>Курс Дивидендная зарплата\n</li>' +
      '<li>Мастер-класс - Облигационная зарплата от Ольги Гогаладзе\n</li>' +
      '<li>Налоги 2024. Самое полное интерактивное пособие по налоговым вычетам\n</li>' +
      '</ul>'
  },
  {
    key: 'combo_8',
    id: '626578:10590,643323:9000',
    title: 'Пакет №8: ',
    name: 'Клуб PREMIUM на год',
    count: 50,
    amount: 0,
    image: '/assets/2024_forAll/box8.png',

    soldout: true,
    description: null,
    mainProducts: [
      {
        name: 'Клуб премиум на 12 месяцев',
        price: 46800,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      }
    ],
    subTitle: null,
    products: [
      {
        name: 'Марафон инвест-идей на 2024–2025 годы',
        price: 16990,
        icon: null
      },

      {
        name: 'Курс Дивидендная зарплата',
        price: 20990,
        icon: null
      },
      {
        name: 'Мастер-класс Облигационная зарплата от Ольги Гогаладзе',
        price: 7900,
        icon: null
      }
    ],

    price: 19590,
    oldPrice: 92680,
    payCredit: 'от 1 632 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 1 632 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Пакет №8. Клуб PREMIUM на год</h2>' +
      '<p><b>Клуб pro.finansy</b> - это сообщество, где приумножают деньги. Получайте свежую и точную аналитику по фондовому рынку. Следите за сделками профессионального трейдера и зарабатывайте вместе с ним. Общайтесь с другими инвесторами и перенимайте их опыт. Задавайте вопросы финансовым консультантам и не тратьте время на самостоятельный поиск ответа. А еще мы встречаемся вместе вживую и ездим в путешествия \n</p>' +
      '<h3>Что получают участники клуба pro.finansy:\n</h3>' +
      '<ul>' +
      '<li>Закрытый телеграм-канал с новостями и аналитикой\n</li>' +
      '<li>Личный кабинет участника клуба в отдельном приложении\n</li>' +
      '<li>Ежемесячный инвест комитет: куда пойдут рынки в этом месяце\n</li>' +
      '<li>Еженедельный инвест апдейт: за чем следить на неделе и разбор новостей\n</li>' +
      '<li>Рубрика «Выбор pro.finansy» — список лучших акций из всех отраслей.\n</li>' +
      '<li>Реальные сделки в реальном портфеле. С аналитикой. Точкой входа и выхода.\n</li>' +
      '<li>Аналитические разборы акций в pdf на 15-20 страниц\n</li>' +
      '<li>Эфиры «Американские горки»: разбор фондового рынка США\n</li>' +
      '<li>Экономический календарь: за чем следить инвестору и как на этом заработать\n</li>' +
      '</ul>' +
      '<h3>В пакет включено:\n</h3>' +
      '<ul>' +
      '<li>резидентство в клубе pro.finansy на 12 месяцев\n</li>' +
      '<li>отдельное приложение для резидентов клуба\n</li>' +
      '<li>Марафон инвест-идей на 2024–2025 годы. Запись\n</li>' +
      '<li>Курс Дивидендная зарплата\n</li>' +
      '<li>Мастер-класс - Облигационная зарплата от Ольги Гогаладзе\n</li>' +
      '</ul>'
  },
  {
    key: 'combo_9',
    id: '192171:9590,643323:9000',
    title: 'Пакет №9: ',
    name: 'Подписка на profinansy.ru на год',
    count: 50,
    amount: 0,
    image: '/assets/2024_forAll/box9.png',

    soldout: true,
    description: null,
    mainProducts: [
      {
        name: 'Подписка на profinansy.ru на 12 мес.',
        price: 29900,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      }
    ],
    subTitle: null,
    products: [
      {
        name: 'Марафон инвест-идей на 2024–2025 годы',
        price: 16990,
        icon: null
      },

      {
        name: 'Курс Дивидендная зарплата',
        price: 20990,
        icon: null
      },
      {
        name: 'Мастер-класс Облигационная зарплата от Ольги Гогаладзе',
        price: 7900,
        icon: null
      }
    ],

    price: 18590,
    oldPrice: 75780,
    payCredit: 'от 1 549 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 1 549 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Пакет №9. Подписка на profinansy.ru НА ГОД </h2>' +
      '<p><b>Premium-подписка на profinansy.ru</b> - позволит находить доходные идеи быстро и легко. Все надёжные инструменты для поиска ценных бумаг всегда будут у вас под рукой \n</p>' +
      '<p>Сайт подходит как начинающим, так и опытным инвесторам. Вам станут доступны больше 10 сервисов в одном месте. Пользуйтесь всеми возможностями через сайт или приложение pro.finansy.\n</p>' +
      '<h3>Чтобы находить доходные идеи, вам не придётся тратить много времени. Достаточно иметь простые и надёжные инструменты под рукой. Вы найдёте их на сайте profinansy.ru:\n</h3>' +
      '<ul>' +
      '<li>Обзор рынка на 360 градусов: котировки, показатели, события\n</li>' +
      '<li>Ведение инвестиционных портфелей и аналитика по ним в одном месте\n</li>' +
      '<li>Доступ к самой полной базе инвестиционных инструментов\n</li>' +
      '<li>Аналитика по инвестиционным инструментам и отчетность за 10 лет\n</li>' +
      '<li>Сервис расчета пассивного дохода от облигаций\n</li>' +
      '<li>Готовые фильтры быстрого подбора инвестиций\n</li>' +
      '<li>Календарь всех событий фондового рынка\n</li>' +
      '</ul>' +
      '<h3>В пакет включено:\n</h3>' +
      '<ul>' +
      '<li>Premium- доступ на 12 месяцев к приложению pro.finansy и аналитическому сервису для инвесторов profinansy.ru\n</li>' +
      '<li>Марафон инвест-идей на 2024–2025 годы. Запись\n</li>' +
      '<li>Курс Дивидендная зарплата\n</li>' +
      '<li>Мастер-класс - Облигационная зарплата от Ольги Гогаладзе\n</li>' +
      '</ul>'
  }
]

export const CARDS_MEDIUM3 = [
  {
    key: 'children',
    id: '643330:9950',
    title: 'Пакет ',
    name: 'детский',
    count: 50,
    amount: 0,
    image: '/assets/2024_forAll/box10.png',

    soldout: true,
    description: null,
    mainProducts: null,
    subTitle: null,

    products: [
      {
        name: 'Настольная игра Мечтаполис',
        price: 12000,
        icon: null
      },

      {
        name: 'Печатная тетрадь для детей «Что такое деньги?»',
        price: 1000,
        icon: null
      },

      {
        name: 'Печатная методичка для родителей «5 шагов к воспитанию успешного ребёнка»',
        price: 1000,
        icon: null
      },

      {
        name: 'Печатный сборник детских игр',
        price: 1000,
        icon: null
      },
      {
        name: 'Коллекция 4-х печатных рабочих тетрадей',
        price: 12000,
        icon: null
      },
      {
        name: 'Экспресс-курс для детей из 3 мультфильмов «Как накопить на мечту»',
        price: 5000,
        icon: null
      },
      {
        name: 'Онлайн-курс «Все, что должен знать ребенок о деньгах в реальной жизни',
        price: 15000,
        icon: null
      },
      {
        name: 'Экспресс-курс по нейросетям для школьников «Как эффективнее заработать первые деньги»',
        price: 15000,
        icon: null
      },
      {
        name: 'Урок для родителей «Как защитить детей в эпоху нейросетей»',
        price: 2000,
        icon: null
      },

      {
        name: 'Аудиокурс «Как защитить себя от мошенников»',
        price: 300,
        icon: '/assets/2024_forAll/giftIcon.png'
      },
      {
        name: 'Экспресс-курс «Детские пособия 2024»',
        price: 300,
        icon: '/assets/2024_forAll/giftIcon.png'
      },
      {
        name: '11 PDF-инструкций и статей для детей и родителей',
        price: 2700,
        icon: '/assets/2024_forAll/giftIcon.png'
      }
    ],

    price: 9950,
    oldPrice: 67000,
    payCredit: 'от 829 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 829 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Пакет детский</h2>' +
      '<h3>В пакет включено:\n</h3>' +
      '<ul>' +
      '<li>Настольная игра Мечтаполис\n</li>' +
      '<li>Печатная тетрадь для детей «Что такое деньги?»\n</li>' +
      '<li>Печатная методичка для родителей «5 шагов к воспитанию успешного ребёнка»\n</li>' +
      '<li>Печатный сборник детских игр\n</li>' +
      '<li>Коллекция 4-х печатных рабочих тетрадей\n</li>' +
      '<li>Экспресс-курс для детей из 3 мультфильмов «Как накопить на мечту»\n</li>' +
      '<li>Онлайн-курс «Все, что должен знать ребенок о деньгах в реальной жизни\n</li>' +
      '<li>Экспресс-курс по нейросетям для школьников «Как эффективнее заработать первые деньги»\n</li>' +
      '<li>Урок для родителей «Как защитить детей в эпоху нейросетей»\n</li>' +
      '<li>Аудиокурс «Как защитить себя от мошенников»\n</li>' +
      '<li>Экспресс-курс «Детские пособия 2024»\n</li>' +
      '<li>11 PDF-инструкций и статей для детей и родителей\n</li>' +
      '</ul>'
  }
]
