import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useBannerStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    pinkBlock: {
      width: '100%',
      backgroundColor: '#000',
      padding: '40px 24px'
    },
    banner: {
      display: 'block',
      maxWidth: 1200,
      width: '100%',
      margin: '0 auto'
    },
    text: {
      maxWidth: 1215,
      textAlign: 'center',
      margin: '0px auto',
      marginTop: '40px',
      fontSize: '26px',
      lineHeight: '132%',
      fontWeight: 900,
      textTransform: 'uppercase',
      color: '#fff',
      '& > span': {
        color: '#FFCA37'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '20px'
      }
    },

    cardsMedium: {
      maxWidth: 1248,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '80px auto 0 auto'
    }
  })
)
