import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useBannerStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '40px',
      padding: '36px 24px',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      background: '#FFF'
    },

    giftLeft: {
      zIndex: 3,
      left: '55px',
      top: '1px',
      position: 'absolute',
      [theme.breakpoints.down(1050)]: {
        display: 'none'
      }
    },
    giftRight: {
      zIndex: 3,
      right: '40px',
      top: '4px',
      position: 'absolute',
      [theme.breakpoints.down(1050)]: {
        display: 'none'
      }
    },
    text: {
      zIndex: 5,
      position: 'relative',
      textAlign: 'center',
      color: '#000',
      fontSize: '30px',
      fontWeight: 900,
      lineHeight: '104%',
      textTransform: 'uppercase',
      [theme.breakpoints.down(1050)]: {
        fontSize: '24px'
      }
    },
    buttons: {
      marginTop: '26px',
      position: 'relative',
      zIndex: 5,
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
      [theme.breakpoints.down(1050)]: {
        flexDirection: 'column',
        gap: '16px'
      }
    },
    buttonDiscount: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      boxShadow: '0px 2.557px 0px 0px #8A0029',
      background: '#EC1656',
      color: '#fff',
      fontSize: 22,
      fontWeight: 700,
      lineHeight: '17px',
      height: '82px',
      maxWidth: '539px',
      [theme.breakpoints.down(1050)]: {
        maxWidth: '100%',
        height: '60px'
      }
    }
  })
)
