import React, { useEffect, useState } from 'react'

import { declOfNum } from '../../../lib'

import { useTimerStyles } from './Timer.styled'

interface IProps {
  text?: string
  targetTime?: Date
  onlineCount?: number
  small?: boolean
}

const Timer = ({ text, targetTime, onlineCount, small }: IProps) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining())
  const classes = useTimerStyles()

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining())
    }, 1000)

    return () => {
      clearInterval(timerInterval)
    }
  }, [targetTime])

  function calculateTimeRemaining() {
    const target = targetTime || new Date(2024, 11, 2, 0, 0, 59)

    const moscow = new Date().toLocaleString('en-US', {
      timeZone: 'Europe/Moscow'
    })
    const now = new Date(moscow)

    const timeDifference = target.getTime() - now.getTime()

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      )
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
      return { days, hours, minutes, seconds }
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
  }

  return small ? (
    <div className={classes.smallTimer}>
      <img
        src="/assets/2024_forAll/imageClockLeft.png"
        className={classes.clockLeft}
      />
      <img
        src="/assets/2024_forAll/imageClockRight.png"
        className={classes.clockRight}
      />
      <span className={classes.textSmall}>
        {text || 'Скидки исчезнут через:'}
      </span>
      <span className={classes.time}>
        {`${String(timeRemaining.days).padStart(2, '0')}:${String(
          timeRemaining.hours
        ).padStart(2, '0')}:${String(timeRemaining.minutes).padStart(
          2,
          '0'
        )}:${String(timeRemaining.seconds).padStart(2, '0')}`}
      </span>
    </div>
  ) : (
    <div className={classes.timer}>
      <img
        src="/assets/2024_forAll/imageClockLeft.png"
        className={classes.clockLeft}
      />
      <img
        src="/assets/2024_forAll/imageClockRight.png"
        className={classes.clockRight}
      />

      <div className={classes.leftSide}>
        <div className={classes.text}>{text || 'Скидки исчезнут через:'}</div>
        <div className={classes.time}>
          {`${String(timeRemaining.days).padStart(2, '0')}:${String(
            timeRemaining.hours
          ).padStart(2, '0')}:${String(timeRemaining.minutes).padStart(
            2,
            '0'
          )}:${String(timeRemaining.seconds).padStart(2, '0')}`}
        </div>
      </div>
      <div className={classes.line} />
      <div className={classes.rightSide}>
        <div className={classes.info}>
          <div className={classes.title}>Сейчас на сайте:</div>
          <div className={classes.dashed} />
          <div className={classes.value}>
            {`${onlineCount} ${declOfNum(onlineCount, [
              'человек',
              'человека',
              'человек'
            ])}`}
          </div>
        </div>

        <div className={classes.info}>
          <div className={classes.title}>Осталось:</div>
          <div className={classes.dashed} />
          <div className={classes.value}>19 мест на пакеты</div>
        </div>
      </div>
    </div>
  )
}

export { Timer }
