import React from 'react'

import { CARDS_BIG } from '../../cardsBig.const'
import { CARDS_MEDIUM2 } from '../../cardsMedium.const'
import { CardsBig } from '../CardsBig/CardsBig'
import { CardMedium } from '../CardsMedium/CardMedium'

import { useBannerStyles } from './Friday2Block.styled'

interface IProps {
  deleteArr: string[]
  clearSoldOut?: boolean
}

const Friday2Block = ({ deleteArr, clearSoldOut }: IProps) => {
  const classes = useBannerStyles()

  const filterCards = (items) => {
    return items.filter((cartItem) => !deleteArr.includes(cartItem.key))
  }

  return (
    <div className={classes.pinkBlock}>
      <img className={classes.banner} src="/assets/2024_forAll/banner2.png" />
      <p className={classes.text2}>
        <span>
          Все пакеты можно купить в рассрочку. Заполнение анкеты займет 2
          минуты.
        </span>
        <span>
          Сейчас вы ничего не платите - первый платеж будет только через месяц!
        </span>
        <span>
          Рассрочка полностью без переплат. Вы можете выбрать рассрочку на 6 или
          12 месяцев
        </span>
      </p>

      <div className={classes.cardsBig}>
        <CardsBig
          data={CARDS_BIG[2]}
          isBig={true}
          minHeight={305}
          clearSoldOut={clearSoldOut}
        />
      </div>

      <div className={classes.cardsMedium}>
        {filterCards(CARDS_MEDIUM2).map((card, index) => (
          <CardMedium data={card} key={index} clearSoldOut={clearSoldOut} />
        ))}
      </div>
    </div>
  )
}

export { Friday2Block }
