export const CARDS_BIG = [
  {
    key: 'combo_1',
    id: '437803:18160,326282:5052,326283:683,640104:2,607893:1,643319:20000,643324:2',
    title: 'Пакет №1: ',
    name: 'Всё для новичков',
    count: 1000,
    amount: 7,
    image: '/assets/2024_forAll/box1.png',
    soldout: false,
    description:
      'Всё про инвестиции для новичков. Начните инвестировать и формировать капитал для получения пассивного дохода',
    mainProducts: [
      {
        name: 'Метод I Всё включено с обновлённым печатным  учебником',
        price: 69800,
        rightText: 'старт в декабре',
        isNew: true,
        icon: null
      },
      {
        name: 'Метод II Всё сам',
        price: 42000,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      },
      {
        name: 'Конструктор финансового плана',
        price: 70000,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      }
    ],
    firstProducts: [
      {
        name: 'Подписка на profinansy.ru за 1₽ на 3 месяца',
        price: 7470,
        icon: null
      },
      {
        name: 'Мастер-класс Женские деньги от Ольги Гогаладзе',
        price: 7900,
        icon: null
      },
      {
        name: 'Курс Как инвестировать в недвижимость в России и курс Как инвестировать в недвижимость за рубежом',
        price: 35000,
        icon: null
      }
    ],

    secondProducts: [
      {
        name: 'Курс Аналитика личных финансов',
        price: 7000,
        icon: null
      },
      {
        name: 'Мастер-класс Облигационная зарплата от Ольги Гогаладзе',
        price: 7900,
        icon: null
      },
      {
        name: 'Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе',
        price: 7990,
        icon: null
      }
    ],

    products: [
      {
        name: 'Курс Дивидендная зарплата',
        price: 20990,
        icon: null
      },

      {
        name: 'Марафон инвест-идей на 2024–2025 годы',
        price: 7900,
        icon: null
      },

      {
        name: 'Курс по техническому анализу I ступень',
        price: 7990,
        icon: null
      },

      {
        name: 'Курс по техническому анализу II ступень',
        price: 7990,
        icon: null
      },

      {
        name: 'Курс по трейдинг-стратегии на инсайдерских сделках SMART money',
        price: 7900,
        icon: null
      },

      {
        name: 'Золотой курс Ольги Гогаладзе',
        price: 16990,
        icon: null
      },

      {
        name: 'Курс Как и зачем инвестировать в нефть',
        price: 16990,
        icon: null
      },

      {
        name: 'Курс Как легко платить налоги от инвестиций',
        price: 16990,
        icon: null
      },

      {
        name: 'Экспресс-курс по экономике',
        price: 12990,
        icon: null
      },

      {
        name: 'Печатный учебник Инвестиции доступны всем',
        price: 'подарок',
        icon: '/assets/2024_forAll/giftIcon.png'
      },

      {
        name: 'Печатные карточки фундаментального анализа',
        price: 'подарок100',
        icon: '/assets/2024_forAll/giftIcon.png'
      }
    ],
    subTitle: {
      name: '12 курсов по финансам и инвестициям:',
      icon: '/assets/2024_forAll/books.png'
    },
    price: 43900,
    oldPrice: 371880,
    payCredit: 'от 3 658 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 3 658 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Пакет №1. Всё для новичков </h2>' +
      '<p><b>Метод управления деньгами с обновленным печатным учебником </b> - это курс по личным финансам и инвестициям, который выведет вас на новый уровень отношений с вашими деньгами всего за 3 месяца.\n</p>' +
      '<h3>Обучение состоит из 3 блоков:\n</h3>' +
      '<ul>' +
      '<li>Личные финансы — узнаете, как правильно вести бюджет и управлять деньгами. Денег будет хватать всегда и на все!\n</li>' +
      '<li>Инвестиции — поймете, куда прибыльно вкладывать деньги. Ваши деньги будут приносить пассивный доход!\n</li>' +
      '<li>Доход и карьера — узнаете, как вырасти в карьере и получать больше, работая меньше. У вас появится еще больше свободных денег, которые вы сможете откладывать!\n</li>' +
      '</ul>' +
      '<h4>Доступ к курсу у вас останется навсегда! Старт потока в декабре \n</h4>' +
      '<p><b>Метод II</b> - этот курс возможно получить только на Черной Пятнице! Основная цель курса – научиться повышать доходность вложений за счёт новых инструментов и усовершенствования вашей действующей стратегии\n</p>' +
      '<p>Вы составите план достижения своей цели в инновационном <b>конструкторе финансового плана</b> с учетом всех ваших запросов всего за 3 шага.\n</p>' +
      '<h3>С помощью конструктора вы:\n</h3>' +
      '<ul>' +
      '<li>проведете анализ личных финансов. По графикам вы увидите, как можно улучшить управление финансами.\n</li>' +
      '<li>составите финансовый план под свою цель. А инновационный искусственный интеллект Портфелекс его проанализирует, подскажет, что можно улучшить, и даст советы, как следовать плану.\n</li>' +
      '<li>увидите свои точки роста и возможности увеличения своего дохода. Это поможет вам быстрее приблизиться к финансовой цели.\n</li>' +
      '</ul>' +
      '<h4>На выходе вы получите готовый финансовый план по достижению своей цели.\n</h4>' +
      '<p><b>Premium-подписка на profinansy.ru</b> позволит находить доходные идеи быстро и легко. Все надёжные инструменты для поиска ценных бумаг всегда будут у вас под рукой\n</p>' +
      '<p><b>Мастер-класс Ольги Гогаладзе Женские деньги</b> - самый красивый, полезный и жизненный урок о том, как быть финансово успешной женщиной: чтобы успевать с деньгами, в отношениях, в работе и с детьми\n</p>' +
      '<p><b>Курс Как инвестировать в недвижимость в России и за рубежом</b> поможет разобраться в какую недвижимость стоит вкладывать\n</p>' +
      '<p><b>12 экспресс- курсов</b> по финансам и инвестициям для углубленного изучения отдельных тем. От аналитики личных финансов до трейдинг-стратегий — всё, что нужно, чтобы увеличить доходность ваших инвестиций и сформировать пассивный доход.\n</p>'
  },

  {
    key: 'combo_2',
    id: '437803:22876,326282:6363,326283:861,640104:4987,269474:44900,643319:20000,643324:2,643329:1',
    title: 'Пакет №2: ',
    name: 'Всё и навсегда',
    count: 100,
    amount: 'РАСПРОДАНО',
    image: '/assets/2024_forAll/box2.png',
    soldout: false,
    description:
      'Это самый полный пакет продуктов Ольги Гогаладзе.Персональный фин консультант скажет, в какой последовательности все изучать под ваши финансовые задачи.',
    mainProducts: [
      {
        name: 'Метод I Всё включено с обновлённым печатным  учебником',
        price: 69800,
        rightText: 'старт в декабре',
        isNew: true,
        icon: null
      },
      {
        name: 'Метод II Всё сам',
        price: 42000,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      },
      {
        name: 'Конструктор финансового плана',
        price: 70000,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      },
      {
        name: 'Подписка на profinansy.ru навсегда',
        price: 64900,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      },
      {
        name: 'Клуб profinansy навсегда',
        price: 69900,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      }
    ],

    firstProducts: [
      {
        name: 'Мастер-класс Ольги Гогаладзе Женские деньги',
        price: 7900,
        icon: null
      },
      {
        name: 'Курс Как инвестировать в недвижимость в России и курс Как инвестировать в недвижимость за рубежом',
        price: 35000,
        icon: null
      }
    ],
    secondProducts: [
      {
        name: 'Курс Аналитика личных финансов',
        price: 7000,
        icon: null
      },
      {
        name: 'Курс по техническому анализу I ступень',
        price: 7990,
        icon: null
      },
      {
        name: 'Курс по техническому анализу II ступень',
        price: 7990,
        icon: null
      },
      {
        name: 'Курс Дивидендная зарплата',
        price: 20990,
        icon: null
      }
    ],
    products: [
      {
        name: 'Курс по трейдинг-стратегии на инсайдерских сделках SMART money',
        price: 7990,
        icon: null
      },

      {
        name: 'Курс Как и зачем инвестировать в нефть',
        price: 16990,
        icon: null
      },

      {
        name: 'Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе',
        price: 7990,
        icon: null
      },

      {
        name: 'Курс Как легко платить налоги от инвестиций',
        price: 16990,
        icon: null
      },

      {
        name: 'Мастер-класс Облигационная зарплата от Ольги Гогаладзе',
        price: 7900,
        icon: null
      },

      {
        name: 'Золотой курс Ольги Гогаладзе',
        price: 16990,
        icon: null
      },

      {
        name: 'Экспресс-курс по экономике',
        price: 12990,
        icon: null
      },

      {
        name: 'Марафон инвест-идей на 2024–2025 годы',
        price: 7900,
        icon: null
      },

      {
        name: 'Печатный учебник Инвестиции доступны всем',
        price: 'подарок',
        icon: '/assets/2024_forAll/giftIcon.png'
      },
      {
        name: 'Футболка pro.finansy',
        price: 'подарок',
        icon: '/assets/2024_forAll/giftIcon.png'
      },
      {
        name: 'Печатные карточки фундаментального анализа',
        price: 'подарок100',
        icon: '/assets/2024_forAll/giftIcon.png'
      }
    ],
    subTitle: {
      name: '12 курсов по финансам и инвестициям:',
      icon: '/assets/2024_forAll/books.png'
    },
    price: 99990,
    oldPrice: 499210,
    payCredit: 'от 8 332 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 8 332 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Пакет №2. Всё и навсегда</h2>' +
      '<h3>Это самый полный пакет продуктов Ольги Гогаладзе!\n</h3>' +
      '<h3>В пакет входят:\n</h3>' +
      '<ul>' +
      '<li>Метод управления деньгами с обновленным печатным учебником\n</li>' +
      '<li>Метод II\n</li>' +
      '<li>Конструктор финансового плана\n</li>' +
      '<li>Клуб pro.finansy навсегда\n</li>' +
      '<li>Подписку на аналитический сервис profinansy.ru навсегда\n</li>' +
      '</ul>' +
      '<p><b>Метод управления деньгами</b> - это курс по личным финансам и инвестициям, который выведет вас на новый уровень отношений с вашими деньгами всего за 3 месяца.\n</p>' +
      '<p><b>Метод II</b> - этот курс возможно получить только на Черной Пятнице! Основная цель курса – научиться повышать доходность вложений за счёт новых инструментов и усовершенствования вашей действующей стратегии\n</p>' +
      '<p><b>Клуб PRO.FINANSY </b> - сообщество, где приумножают деньги! Здесь вы сможете получать прибыльные сделки и актуальную аналитику в компании единомышленников.\n</p>' +
      '<p><b>Premium-подписка на profinansy.ru</b> позволит находить доходные идеи быстро и легко. Все надёжные инструменты для поиска ценных бумаг всегда будут у вас под рукой\n</p>' +
      '<p><b>Мастер-класс Ольги Гогаладзе Женские деньги</b> - самый красивый, полезный и жизненный урок о том, как быть финансово успешной женщиной: чтобы успевать с деньгами, в отношениях, в работе и с детьми\n</p>' +
      '<p><b>Курс Как инвестировать в недвижимость в России и за рубежом</b> поможет разобраться в какую недвижимость стоит вкладывать\n</p>' +
      '<p><b>12 экспресс- курсов</b> по финансам и инвестициям для углубленного изучения отдельных тем. От аналитики личных финансов до трейдинг-стратегий — всё, что нужно, чтобы увеличить доходность ваших инвестиций и сформировать пассивный доход.\n</p>'
  },

  {
    key: 'combo_5',
    id: '269474:47990,640104:4999,643322:9000,643411:1',
    title: 'Пакет №5: ',
    name: 'Гениальный инвестор: участие в клубе НАВСЕГДА+ Аналитический сервис для инвесторов profinansy.ru навсегда+ Метод II + 28 курсов по финансам и инвестициям',
    count: 200,
    amount: 9,
    image: '/assets/2024_forAll/box5.png',
    soldout: false,
    description: null,
    mainProducts: [
      {
        name: 'Клуб премиум навсегда',
        price: 69900,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      },
      {
        name: 'Подписка на profinansy.ru навсегда',
        price: 64900,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/starIcon.png'
      },
      {
        name: 'Метод II Всё сам',
        price: 42000,
        rightText: null,
        isNew: false,
        icon: '/assets/2024_forAll/giftIcon.png'
      }
    ],
    firstProducts: [
      {
        name: 'Все 22 курса клуба за все время',
        price: null,
        icon: null
      },
      {
        name: 'Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе',
        price: 7990,
        icon: null
      }
    ],
    secondProducts: [],
    products: [
      {
        name: 'Мастер-класс Ольги Гогаладзе Женские деньги',
        price: 7900,
        icon: null
      },

      {
        name: 'Курс Как инвестировать в недвижимость в России и курс Как инвестировать в недвижимость за рубежом',
        price: 35000,
        icon: null
      },
      {
        name: 'Печатный учебник Инвестиции доступны всем',
        price: 'подарок',
        icon: '/assets/2024_forAll/giftIcon.png'
      }
    ],
    subTitle: null,
    price: 61990,
    oldPrice: 254790,
    payCredit: 'от 5 165 р./ мес.',
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'tinkoff',
        subtext: 'от 5 165 ₽ в месяц'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h3>Пакет №5 Гениальный инвестор: участие в клубе НАВСЕГДА+ Аналитический сервис для инвесторов profinansy.ru навсегда+ Метод II + 28 курсов по финансам и инвестициям</h3>' +
      '<p><b>Клуб PRO.FINANSY </b> - сообщество, где приумножают деньги! Здесь вы сможете получать прибыльные сделки и актуальную аналитику в компании единомышленников.\n</p>' +
      '<p>Следите за сделками профессионального трейдера и зарабатывайте вместе с ним. Общайтесь с другими инвесторами и перенимайте их опыт. Задавайте вопросы финансовым консультантам и не тратьте время на самостоятельный поиск ответа. А еще мы встречаемся вместе вживую и ездим в путешествия)\n</p>' +
      '<h3>Что получают участники клуба pro.finansy:\n</h3>' +
      '<ul>' +
      '<li>Закрытый телеграм-канал с новостями и аналитикой\n</li>' +
      '<li>Личный кабинет участника клуба в отдельном приложении\n</li>' +
      '<li>Ежемесячный инвест комитет: куда пойдут рынки в этом месяце\n</li>' +
      '<li>Еженедельный инвест апдейт: за чем следить на неделе и разбор новостей\n</li>' +
      '<li>Рубрика «Выбор pro.finansy» — список лучших акций из всех отраслей.\n</li>' +
      '<li>Реальные сделки в реальном портфеле. С аналитикой. Точкой входа и выхода.\n</li>' +
      '<li>Аналитические разборы акций в pdf на 15-20 страниц\n</li>' +
      '<li>Эфиры «Американские горки»: разбор фондового рынка США\n</li>' +
      '<li>Экономический календарь: за чем следить инвестору и как на этом заработать\n</li>' +
      '</ul>' +
      '<p><b>Premium-подписка на profinansy.ru</b> позволит находить доходные идеи быстро и легко. Все надёжные инструменты для поиска ценных бумаг всегда будут у вас под рукой\n</p>' +
      '<p>Сайт подходит как начинающим, так и опытным инвесторам. Вам станут доступны больше 10 сервисов в одном месте. Пользуйтесь всеми возможностями через сайт или приложение pro.finansy.\n</p>' +
      '<h3>Чтобы находить доходные идеи, вам не придётся тратить много времени. Достаточно иметь простые и надёжные инструменты под рукой. Вы найдёте их на сайте profinansy.ru:\n</h3>' +
      '<ul>' +
      '<li>Обзор рынка на 360 градусов: котировки, показатели, события\n</li>' +
      '<li>Ведение инвестиционных портфелей и аналитика по ним в одном месте\n</li>' +
      '<li>Доступ к самой полной базе инвестиционных инструментов\n</li>' +
      '<li>Аналитика по инвестиционным инструментам и отчетность за 10 лет\n</li>' +
      '<li>Сервис расчета пассивного дохода от облигаций\n</li>' +
      '<li>Готовые фильтры быстрого подбора инвестиций\n</li>' +
      '<li>Календарь всех событий фондового рынка\n</li>' +
      '</ul>' +
      '<h3>В пакет включено:\n</h3>' +
      '<ul>' +
      '<li>22 продвинутых курса по инвестициям для резидентов Клуба\n</li>' +
      '<li>подписка на аналитический сервис и приложение pro.finansy НАВСЕГДА\n</li>' +
      '<li>резидентство в клубе pro.finansy НАВСЕГДА\n</li>' +
      '<li>отдельное приложение для резидентов клуба\n</li>' +
      '<li>Метод II Всё сам.\n</li>' +
      '<li>Курс Как инвестировать в недвижимость в России и курс Как инвестировать в недвижимость за рубежом\n</li>' +
      '<li>Мастер-класс Ольги Гогаладзе Женские деньги\n</li>' +
      '<li>Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе\n</li>' +
      '</ul>'
  }
]
