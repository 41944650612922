import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import Raf from 'raf'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List
} from 'react-virtualized'

import { banUser, getComments } from '../api/api'
import { withUserContext, withUserContextProps } from '../context/userContext'

import InputComment from './InputComment'
import whileRequest from './lib/whileRequest'

const CellMeasurerCustom = CellMeasurer as any
const ListCustom = List as any
const AutoSizerCustom = AutoSizer as any

interface Props
  extends WithStyles<typeof style>,
    withUserContextProps,
    WithSnackbarProps {
  online: number
  isAdmin: boolean
  ukey: string
  fastComments: boolean
}

const style = (theme: Theme) =>
  createStyles({
    box: {},

    item: {
      display: 'flex',
      padding: '10px 24px'
    },
    itemIcon: {
      width: 27,
      height: 27,
      marginRight: 11,
      backgroundColor: '#4799F4',
      fontWeight: 500,
      fontSize: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      borderRadius: '50%'
    },
    itemTitle: {
      marginBottom: 4,
      color: '#A1A0A3'
    },
    content: {
      flex: 1,
      overflow: 'hidden'
    },
    itemContent: {
      backgroundColor: '#383838',
      color: '#fff',
      borderRadius: '16px 16px 16px 5px',
      padding: 10,
      wordBreak: 'break-word'
    },
    infoBlock: {
      height: 50,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      padding: '0 20px',
      borderBottom: '1px solid #DFDFDE'
    },
    infoBlockText: {
      fontSize: 20,
      fontWeight: 700,
      marginRight: 20,
      '&:last-child': {
        marginRight: 0
      }
    },
    countInStream: {
      backgroundColor: '#6553F5',
      padding: '4px 12px',
      borderRadius: 100,
      fontSize: 14,
      fontWeight: 700
    },
    ban: {
      color: 'red',
      cursor: 'pointer',
      marginLeft: 5,
      fontWeight: 700
    }
  })

const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789          '

function generateString(length: number) {
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

class Comments extends React.PureComponent<Props> {
  state = {
    open: false,
    full: true
  }

  initial = true
  comments = []

  mapWrited = {}

  offset = 0

  _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 0,

    keyMapper: (rowIndex: number, columnIndex: number) => {
      return this.comments[rowIndex].id
    }
  })

  setBan = (id: number, name: string) => {
    banUser(
      {
        message_id: id
      },
      this.props.ukey,
      name
    ).then((data) => {
      if (data.ok) {
        this.props.enqueueSnackbar('успешно забанен', {
          variant: 'success'
        })
      }
    })
  }

  _rowRenderer = ({ index, key, parent, style }) => {
    const item = this.comments[index]

    const classes = this.props.classes

    return (
      <CellMeasurerCustom
        cache={this._cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {({ measure, registerChild }) => {
          return (
            <div style={style} ref={registerChild}>
              <div className={classes.item}>
                <div className={classes.itemIcon}>
                  {item.name
                    .split(' ')
                    .map((el) => el[0])
                    .join('')}
                </div>

                <div className={classes.content}>
                  <div className={classes.itemTitle}>{item.name}</div>

                  <div className={classes.itemContent}>
                    <span>{item.comment}</span>

                    {this.props.isAdmin ? (
                      <span
                        className={classes.ban}
                        onClick={this.setBan.bind(this, item.id, item.name)}
                      >
                        Забанить
                      </span>
                    ) : null}

                    {/*<span className={classes.ban} onClick={this.setBan.bind(this, item.id)}>Забанить</span>*/}
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </CellMeasurerCustom>
    )
  }

  list = React.createRef<any>()

  handleScroll = (e) => {
    const { scrollTop, scrollLeft } = e.target
    const { Grid } = this.list.current

    Grid.handleScrollEvent({ scrollTop, scrollLeft })
  }

  scrollbars = React.createRef<any>()

  interval: any

  raf
  timeout: any
  addComment = false
  firstLoad = true

  tick = () => {
    const TIME_out = this.props.fastComments ? 1000 : 30000

    this.raf = Raf((timestamp) => {
      if (!this.initialTimestamp) this.initialTimestamp = timestamp

      if (this.needTicksComments.length === 0) {
        this.timeout = setTimeout(() => {
          this._load()
        }, Math.max(0, TIME_out - (timestamp - this.initialTimestamp)))

        return
      }

      const progress = (timestamp - this.initialTimestamp) / TIME_out
      const canQueue = Math.round(
        Math.max(
          0,
          this.needTicksCommentsLength - progress * this.needTicksCommentsLength
        )
      )

      if (this.needTicksComments.length > canQueue) {
        this.comments.push(this.needTicksComments[0])
        this.needTicksComments.splice(0, 1)
        this.addComment = true

        if (this.comments.length > 550) {
          this.comments.slice(0, this.comments.length - 500).forEach((item) => {
            //@ts-ignore
            delete this._cache._cellHeightCache[item.id]

            //@ts-ignore
            delete this._cache._cellWidthCache[item.id]

            //@ts-ignore
            delete this._cache._rowHeightCache[item.id]
          })

          this.comments.splice(0, this.comments.length - 500)
        }

        this.forceUpdate()
      }

      this.tick()
    })
  }

  request

  needTicksComments = []
  needTicksCommentsLength = 0
  initialTimestamp

  _load = () => {
    const TIME_out = this.props.fastComments ? 5000 : 30000

    this.request = whileRequest(
      getComments,
      {
        offset: this.offset
      },
      this.props.ukey
    )

    this.request.then((data) => {
      data.data = data.data.filter((el) => {
        return this.mapWrited[el.id] !== true
      })

      if (data.data.length > 0) {
        this.offset = data.data[data.data.length - 1].id
      }

      if (this.firstLoad) {
        this.comments = data.data
        this.forceUpdate()

        this.timeout = setTimeout(() => {
          this._load()
        }, TIME_out)
      } else {
        this.needTicksComments = data.data
        this.initialTimestamp = undefined
        this.needTicksCommentsLength = data.data.length

        this.tick()
      }
    })
  }

  componentDidMount() {
    this._load()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    Raf.cancel(this.raf)

    if (this.request) {
      this.request.abort()
    }

    clearTimeout(this.timeout)
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (this.firstLoad) {
      this.firstLoad = false

      if (this.fixedBottom) {
        this.list.current.scrollToRow(this.comments.length)
      }

      this.forceUpdate()
    }

    if (this.addComment) {
      this.addComment = false

      if (this.fixedBottom) {
        this.list.current.scrollToRow(this.comments.length)
      }
    }
  }

  fixedBottom = true

  onScroll = (e) => {
    this.fixedBottom = e.scrollHeight - e.scrollTop - this.height <= 100
  }

  newComment = (comment) => {
    this.mapWrited[comment.id] = true

    this.comments.push(comment)
    this.forceUpdate(() => {
      this.list.current.scrollToRow(this.comments.length)
    })
  }

  height = 0

  render() {
    const { classes } = this.props

    return (
      <AutoSizerCustom>
        {({ height, width }) => {
          this.height = height

          return (
            <div style={{ width }}>
              <div className={classes.infoBlock}>
                <div className={classes.infoBlockText}>Чат</div>
                <div
                  className={classes.infoBlockText}
                  style={{ color: '#9B98A1' }}
                >
                  Участники
                </div>
                <div className={classes.countInStream}>
                  {`${this.props.online > 999999 ? '>' : ''}${Math.min(
                    999999,
                    this.props.online
                  ).toLocaleString('ru-RU')}`}
                </div>
              </div>

              <ListCustom
                height={height - 90}
                width={width}
                deferredMeasurementCache={this._cache}
                overscanRowCount={this.firstLoad ? this.comments.length : 10}
                rowHeight={this._cache.rowHeight}
                rowRenderer={this._rowRenderer}
                ref={this.list}
                rowCount={this.comments.length}
                onScroll={this.onScroll}
              />

              <InputComment
                ukey={this.props.ukey}
                newComment={this.newComment}
                isAdmin={this.props.isAdmin}
              />
            </div>
          )
        }}
      </AutoSizerCustom>
    )
  }
}

export default withSnackbar(withUserContext(withStyles(style)(Comments))) as any
