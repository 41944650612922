export const CARDS = [
  {
    key: 'intensiveDebt',
    id: '640369:99',
    image: '/assets/2024_forAll/packet1.png',
    name: 'Интенсив Долговая разгрузка',
    description:
      'Интенсив о том, как снизить долговую нагрузку и погасить кредиты',
    price: 99,
    oldPrice: 1990,
    soldout: true,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Авторская методика Ольги Гогаладзе по избавлению от долгов и кредитов</h2>' +
      '<h3>Содержание курса: </h3>' +
      '<ul>' +
      '<li>то, что нужно знать про кредиты;</li>' +
      '<li>банкротство физических лиц;</li>' +
      '<li>рефинансирование кредитов и начало инвестирования;</li>' +
      '<li>все об ипотеке.</li>' +
      '</ul>' +
      '<p>Вас будут ждать 4 подробных урока и методичка на 59 страниц с инструкциями по избавлению от кредитов 🔥</p>'
  },
  {
    key: 'dividend_salary',
    id: '640352:3900',
    image: '/assets/2024_forAll/dividend_salary.png',
    name: 'Курс Дивидендная зарплата',
    description:
      'Составьте дивидендный портфель, который будет приносить пассивный доход',
    price: 3900,
    oldPrice: 20990,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Дивидендная зарплата</h2>' +
      '<p>Хотите получать зарплату, но не работать? Тогда вам точно сюда. В курсе рассказывается про формирование дивидендного портфеля - инвестиций, которые будут приносить пассивный доход в рублях и валюте. На уроках вы узнаете:</p>' +
      '<ul>' +
      '<li>что такое дивиденды, и почему инвесторы так их любят\n</li>' +
      '<li>когда наступает дивидендный сезон, и нужно ли его ждать для покупки акций\n</li>' +
      '<li>как найти и выбрать компании, которые платят лучшие дивиденды в рублях и валюте\n</li>' +
      '<li>как еще можно заработать на дивидендных выплатах\n</li>' +
      '<p>Уроки от аналитиков pro.finansy, готовые списки акций, практика по поиску лучших дивидендых компаний помогут вам составить дивидендный портфель с регулярными выплатами денег.</p>' +
      '</ul>'
  },
  {
    key: 'crypto',
    id: '640356:3900',
    image: '/assets/2024_forAll/packet3.png',
    name: 'Курс Как покупать и хранить криптовалютуот Павла Гогаладзе',
    description: 'Научитесь пользоваться криптовалютами',
    price: 3900,
    oldPrice: 7990,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе</h2>' +
      '<p>Курс научит пользоваться криптовалютами даже тех, кто с ними совсем не знаком. Из уроков вы узнаете:\n</p>' +
      '<ul>' +
      '<li>что такое криптовалюта, и какой она бывает</li>' +
      '<li>как используют криптовалюту в современном мире</li>' +
      '<li>где и как безопасно покупать, продавать, хранить и переводить криптовалюту</li>' +
      '<p>В курс включены уроки с теорией и практикой, а также бонусные уроки и методическое пособие. Всё это позволит вам начать использовать валюту 21 века уже во время обучения.</p>' +
      '</ul>'
  },
  {
    key: 'iis',
    id: '643402:299',
    image: '/assets/2024_forAll/packet4.png',
    name: 'Курс Новый ИИС типа 3',
    description:
      'Узнайте всё про работу нового ИИС и получите +13% доходности к вашим инвестициям',
    price: 299,
    oldPrice: 1490,
    soldout: true,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Индивидуальный инвестиционный счет (ИИС) - брокерский счет для инвестиций, на котором можно получать налоговые вычеты от государства. Узнайте всё про работу ИИС и получите +13% доходности к вашим инвестициям.</h2>' +
      '<p>На курсе вы узнаете:\n</p>' +
      '<ul>' +
      '<li>В чем разница между ИИС и брокерским счетом;</li>' +
      '<li>Кто может открыть ИИС и как его закрывать;</li>' +
      '<li>Как через ИИС возвращать налоги на сумму до 52 000 рублей ежегодно;</li>' +
      '<p>Как на ИИС можно инвестировать и не платить налог на прибыль.</p>' +
      '</ul>'
  },
  {
    key: 'bond_salary',
    id: '640358:3900',
    image: '/assets/2024_forAll/packet5.png',
    name: 'Мастер-класс Облигационная зарплата',
    description:
      'Создаем источник пассивного дохода от облигаций в период высоких ставок',
    price: 3900,
    oldPrice: 7900,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Мастер-класс Облигационная зарплата от Ольги Гогаладзе</h2>' +
      '<p>Топовый мастер-класс от Ольги Гогаладзе по работе с облигациями в рублях и валюте. На уроках вы узнаете:</p>' +
      '<ul>' +
      '<li>как получить доходность 40-50% от самого консервативного инструмента фондового рынка</li>' +
      '<li>5 стратегий инвестиций в облигации</li>' +
      '<li>чем инвестиции в иностранные облигации отличаются от вложений в российские облигации</li>' +
      '<li>почему российским инвесторам тоже нужны облигации в валюте</li>' +
      '<li>как выбрать и купить еврооблигации на практике</li>' +
      '<li>можно ли увеличить доходность вложений в облигации</li>' +
      '<p>В уроках вас ждут практические инструкции по выбору облигаций в разной валюте и 10+ готовых списков облигаций от аналитиков pro.finansy. Всё это позволит вам создать регулярный доход от самого безопасного инструмента фондового рынка.</p>' +
      '</ul>'
  },
  {
    key: 'movie',
    id: '643404:199',
    image: '/assets/2024_forAll/packet6.png',
    name: 'Разбор фильма «Золото»',
    description:
      'Смотрите разбор фильма и узнаете как канадская компания Bre-X Minerals Ltd. поставила на уши весь мир и заставила поменять правила игры на фондовых рынках!',
    price: 199,
    oldPrice: 990,
    soldout: true,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Смотрите разбор фильма и узнаете как канадская компания Bre-X Minerals Ltd. поставила на уши весь мир и заставила кардинально поменять правила игры на фондовых рынках!</h2>'
  },
  {
    key: 'book1',
    id: '643456:2900',
    image: '/assets/2024_forAll/packet7.png',
    name: 'Гениальный ежедневник + курс деньги под контролем',
    description:
      'Превращайте свои мечты в финансовые цели и составьте свой финансовый календарь',
    price: 2900,
    oldPrice: 3900,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Гениальный ежедневник Ольги Гогаладзе +мастер-класс деньги под контролем</h2>' +
      '<ul>' +
      '<li>превращай свои мечты в финансовые цели</li>' +
      '<li>составь свой финансовый календарь на год</li>' +
      '<li>веди учет доходов по источникам и расходов по категориям</li>' +
      '<li>снижай импульсивные траты на 30%</li>' +
      '<li>переходи по QR-кодам и смотри бесплатные уроки по финансам</li>' +
      '</ul>'
  },
  {
    key: 'book2',
    id: '643457:3900',
    image: '/assets/2024_forAll/packet8.png',
    name: 'Гениальный ежедневник в коробке + курс деньги под контролем',
    description:
      'Превращайте свои мечты в финансовые цели и составьте свой финансовый календарь',
    price: 3900,
    oldPrice: 4700,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Гениальный ежедневник Ольги Гогаладзе +мастер-класс деньги под контролем</h2>' +
      '<ul>' +
      '<li>превращай свои мечты в финансовые цели</li>' +
      '<li>составь свой финансовый календарь на год</li>' +
      '<li>веди учет доходов по источникам и расходов по категориям</li>' +
      '<li>снижай импульсивные траты на 30%</li>' +
      '<li>переходи по QR-кодам и смотри бесплатные уроки по финансам</li>' +
      '</ul>'
  }
]

export const CARDS2 = [
  {
    key: 'magazine',
    id: '643332:2100',
    image: '/assets/2024_forAll/col1.png',
    name: 'Комплект журналов 8 шт',
    description:
      'Полный комплект раритетных журналов про деньги, инвестиции и личную жизнь. Выпуски: январь 2022 - август 2022. Бесплатная доставка по РФ',

    price: 2100,
    oldPrice: 4000,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Комплект журналов 8 шт</h2>' +
      '<h3>Полный комплект раритетных журналов про деньги, инвестиции и личную жизнь. Выпуски: январь 2022 - август 2022. Бесплатная доставка по РФ</h3>'
  },
  {
    key: 'magazine2',
    id: '643333:1400',
    image: '/assets/2024_forAll/col2.png',
    name: 'Комплект журналов 4 шт',
    description:
      'Полный комплект раритетных журналов про деньги, инвестиции и личную жизнь.Выпуски: май 2022 - август 2022. Бесплатная доставка по РФ',

    price: 1400,
    oldPrice: 2000,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Комплект журналов 4 шт</h2>' +
      '<h3>Полный комплект раритетных журналов про деньги, инвестиции и личную жизнь.Выпуски: май 2022 - август 2022. Бесплатная доставка по РФ</h3>'
  },
  {
    key: 'magazine3',
    id: '643411:690',
    image: '/assets/2024_forAll/col3.png',
    name: 'Учебник Инвестиции доступны всем',
    description:
      'Печатная методичка программы «Инвестиции доступны всем», 7 поток',

    price: 690,
    oldPrice: 990,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Учебник Инвестиции доступны всем</h2>' +
      '<h3>Печатная методичка программы «Инвестиции доступны всем», 7 поток</h3>'
  },
  {
    key: 'magazine4',
    id: '643331:2600',
    image: '/assets/2024_forAll/col4.png',
    name: 'Печатная продукция',
    description: null,

    price: 2600,
    oldPrice: null,
    soldout: false,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Печатная продукция</h2>' +
      '<ul>' +
      '<li>Еженедельник инвестора от pro.finansy</li>' +
      '<li>Фирменная ручка pro.finansy</li>' +
      '<li>Карандаш pro.finansy</li>' +
      '<li>Тетрадь Мои гениальные конспекты</li>' +
      '<li>Наклейки и стикерпак pro.finansy</li>' +
      '</ul>'
  }
]
